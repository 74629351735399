import { useQuery } from 'react-query';
import { nextApiRequest } from '../utils';

export const getPremiumPlanLink = async (
    isPremium: boolean,
    uuid?: string
): Promise<{ premiumPlanLink: string | false }> => {
    if (!uuid || !isPremium) {
        return { premiumPlanLink: false };
    }

    return nextApiRequest<{ premiumPlanLink: string | false }>(`/user/${uuid}/premium-plan-link`);
};

export const useGetPremiumPlanLink = (isPremium: boolean, uuid?: string) => {
    const query = useQuery(['checkPremium', { isPremium, uuid }], () => getPremiumPlanLink(isPremium, uuid));

    return query;
};

import { sendAnalyticsEvent } from '../utils';

// Fired when intercom has failed to load (one time event)

export const intercomNotLoadedEvent = (email?: string, uuid?: string) =>
    sendAnalyticsEvent({
        uuid,
        email,
        eventName: 'intercom_not_loaded'
    });

import { sendAnalyticsEvent } from '../utils';

// Fired when the test mode is toggled from the header

export const testModeClickedEvent = (route: string | null, email?: string, uuid?: string) =>
    sendAnalyticsEvent({
        uuid,
        email,
        eventName: 'test_mode_clicked',
        data: {
            route
        }
    });

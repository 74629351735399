import { FC } from 'react';

export const UnChecked: FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Group_18134" data-name="Group 18134" transform="translate(-332 -1759)">
            <g
                id="Rectangle_3705"
                data-name="Rectangle 3705"
                transform="translate(332 1759)"
                fill="none"
                stroke="#f1860d"
                strokeWidth="2"
            >
                <rect width="24" height="24" stroke="none" />
                <rect x="1" y="1" width="22" height="22" fill="none" />
            </g>
        </g>
    </svg>
);

import { vulcanApiRequest } from '../utils';
import { SanityGame, SanityTrackNameAndId } from 'src/types/sanity';
import { VulcanResponse } from '../types';
import { SanityCar } from 'src/types/new';
import { AxiosError } from 'axios';
import { SessionActiveFilters, SessionFilters } from './getSessionsFilters';

export enum SessionStatus {
    LIMITED = 'LIMITED',
    REFERENCE = 'REFERENCE',
    PRIVATE = 'PRIVATE'
}

export interface Session {
    id: string;
    user_id: string;
    car: string;
    driver_name: string | null;
    game_id: string;
    track_id: string;
    weather_id: string | null;
    session_type: string;
    session_type_supported: boolean;
    season_id: string | null;
    track_grip: string | null;
    game_version: string | null;
    ghost_version: string;
    timestamp: string;
    last_updated: string | null;
    is_blocked: boolean | null;
    car_performance_index: number | null;
    car_class: string | null;
    leaderboard_block: boolean | null;
    bestLapTime: number;
    sanity_car?: SanityCar;
    sanity_track?: SanityTrackNameAndId;
    sanity_game?: SanityGame;
    status: 'FAILED' | 'PROCESSING' | 'PROCESSED';
    trackName?: string;
    carName?: string;
    limited_status?: SessionStatus;
}

export type SessionsResponse = {
    sessions: Session[];
    page: number;
    limit: number;
    count: number;
};

export const getSessions = async (
    uuid?: string,
    limit?: number,
    page: number = 1,
    activeFilters: SessionActiveFilters = {
        cars: [],
        tracks: [],
        game: null
    }
): Promise<VulcanResponse<SessionsResponse> | undefined> => {
    if (!uuid) {
        return undefined;
    }

    const urlSearchParams = new URLSearchParams();

    urlSearchParams.append('page', page.toString());

    if (limit) urlSearchParams.append('limit', limit.toString());

    activeFilters.cars.forEach((car) => urlSearchParams.append('cars', car.id));
    activeFilters.tracks.forEach((track) => urlSearchParams.append('tracks', track.id));
    activeFilters.game && urlSearchParams.append('games', activeFilters.game.id);

    try {
        const response = await vulcanApiRequest<SessionsResponse>(
            `/users/${uuid}/sessions?${decodeURI(urlSearchParams.toString())}`
        );

        return response;
    } catch (error) {
        const axiosError = error as AxiosError;

        if (axiosError.response?.status === 404) {
            return {
                success: true,
                data: {
                    sessions: [],
                    page,
                    limit: 10,
                    count: 0
                },
                status: 200,
                message: 'No sessions found'
            };
        }

        throw error;
    }
};

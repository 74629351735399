import { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';

import { downloadDesktopApplication } from 'src/utilities';

import { OnboardingIntro } from './OnboardingIntro';
import { DataLogger } from './DataLogger';
import { Progress } from './Progress';
import { Games } from './Games';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/type';
import { hotjar } from 'react-hotjar';
import { UserType } from 'src/types';
import { SupportedGame } from 'components/SupportedGames';
import { SanityImage } from 'src/types/sanity';
import Modal from 'components/Modal';

interface IModalOnboarding {
    slide: number;
    setSlide: (slide: number) => void;
    ghostEventDownloaded: boolean;
    setGhostEventDownloaded: (ghostEventDownloaded: boolean) => void;
    completedCountdown: boolean;
    setCompletedCountdown: (completedCountdown: boolean) => void;
    isOnboardingModalOpen: boolean;
    setIsOnboardingModalOpen: (isOnboardingModalOpen: boolean) => void;
    user?: UserType;
    supportedGames?: SupportedGame[];
    onboardingMiscImage?: SanityImage;
}

export const ModalOnboarding = ({
    slide,
    setSlide,
    ghostEventDownloaded,
    setGhostEventDownloaded,
    user,
    completedCountdown,
    setCompletedCountdown,
    isOnboardingModalOpen,
    setIsOnboardingModalOpen,
    supportedGames,
    onboardingMiscImage
}: IModalOnboarding) => {
    // "slide" as in a page of the modal
    const [showDownloadSlide2, setShowDownloadSlide2] = useState(false);

    const handleDownloadClick = () => {
        if (user?.uuid) {
            downloadDesktopApplication(user.uuid, user.email!, ghostEventDownloaded, setGhostEventDownloaded);
            setSlide(2);
        }
    };

    const handleCloseModal = () => setIsOnboardingModalOpen(false);

    const handleCloseAndReset = () => {
        setIsOnboardingModalOpen(false);
        setSlide(0);
    };

    const handleBack = () => {
        if (slide && slide > 1) {
            setSlide(slide - 1);
            setShowDownloadSlide2(false);
        }
    };

    const getTitle = () => {
        switch (slide) {
            case 0:
            case 1:
            case 2:
                return 'Get the Desktop App';
            case 3:
                return 'Select your game';
            default:
                break;
        }
    };

    useEffect(() => {
        if (isOnboardingModalOpen) {
            hotjar.event('onboarding-open');
        }
    }, [isOnboardingModalOpen]);

    return (
        <Modal open={isOnboardingModalOpen} closeModal={handleCloseModal} maxWidth="48rem">
            <div>
                <Progress currentSlide={slide || 1} numberOfSlides={3} />

                <Dialog.Title as="h2" className="text-5xl leading-6 text-center pt-6 pb-4">
                    {getTitle()}
                </Dialog.Title>

                {(slide === 0 || slide === 1) && (
                    <OnboardingIntro
                        goNextSlide={() => setSlide(2)}
                        setShowDownloadSlide2={setShowDownloadSlide2}
                        handleDownloadClick={handleDownloadClick}
                        supportedGames={supportedGames}
                    />
                )}
                {slide === 2 && (
                    <DataLogger
                        goNextSlide={() => setSlide(3)}
                        showDownloadButton={showDownloadSlide2}
                        handleDownloadClick={handleDownloadClick}
                        completedCountdown={completedCountdown}
                        setCompletedCountdown={setCompletedCountdown}
                        onboardingMiscImage={onboardingMiscImage}
                    />
                )}
                {slide === 3 && <Games closeModal={handleCloseAndReset} supportedGames={supportedGames} />}

                <div className={`flex justify-end px-8 py-2 bg-grey-00 mt-0 -m-6`}>
                    {!!slide && slide > 1 && (
                        <Button onClick={handleBack} buttonType={ButtonType.InvertedWhite} className="mr-4">
                            Back
                        </Button>
                    )}
                </div>
            </div>
        </Modal>
    );
};

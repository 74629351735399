import { sendAnalyticsEvent } from 'src/analytics/utils';
import { vulcanApiRequest } from '../utils';

export const getSanity = async <T>(query: string, uuid?: string, eventName?: string): Promise<T | undefined> => {
    if (!query) return undefined;

    const encoded = encodeURIComponent(query);

    try {
        const response = await vulcanApiRequest<T>(`/sanity?query=${encoded}`, {}, 60 * 60);

        if (eventName) {
            await sendAnalyticsEvent({
                eventName,
                uuid
            });
        }

        return response?.data || undefined;
    } catch (error) {
        console.error('Error fetching sanity data', error);
        return undefined;
    }
};

import Link from 'next/link';

import { sideCommunityBannerClickedEvent } from 'src/analytics/layout/sideCommunityBannerClicked';
import { UserType } from 'src/types';
import styles from './index.module.css';

interface ISidebanner {
    user?: UserType;
    isSessionOrSessionPage: boolean;
}

export const SideBanner = ({ user, isSessionOrSessionPage }: ISidebanner) => {
    const hideBanner = user?.isPremiumPlan || user?.isUltraPlan || isSessionOrSessionPage;
    const isCommunity = !user?.isPlusPlan && !user?.isPremiumPlan && !user?.isUltraPlan;
    return (
        <>
            {!hideBanner && (
                <div
                    className={`bg-newBackground p-4 mt-4 mx-4 flex flex-col gap-1 items-start justify-start rounded-md ${styles.sideBarBanner}`}
                >
                    <p className="font-bold text-lg">
                        {user?.isPlusPlan ? 'Plus Membership  🚀' : 'Community Membership 🚀'}
                    </p>
                    <p className="flex-1">
                        {!user?.isPlusPlan &&
                            `Community members are limited to recording 50 laps. To unlock unlimited laps and insight,
                        upgrade to Plus, Ultra or Premium membership.`}
                        {user?.isPlusPlan &&
                            `Upgrade to Track Titan Ultra membership to unlock unlimited laps, insights and HYMO Esports Setups combined`}
                    </p>
                    <Link
                        href="/pricing"
                        className="bg-newOrange text-black rounded w-full py-1 text-center mt-1"
                        onClick={() => sideCommunityBannerClickedEvent(isCommunity, user?.email, user?.uuid)}
                    >
                        {user?.isPlusPlan ? `Get Ultra` : `Get Unlimited Laps`}
                    </Link>
                </div>
            )}
        </>
    );
};

import { FC } from 'react';
import { useNextSanityImage } from 'next-sanity-image';
import Image from 'next/image';

import { SanityImage } from 'src/types/sanity';
import { client } from 'src/sanity';
import { Img } from 'remotion';

type SanityNextImageProps = {
    sanityImage: SanityImage;
    alt?: string;
    sizes?: string;
    className?: string;
    onLoadingComplete?: (result: { naturalWidth: number; naturalHeight: number }) => void;
    fill?: boolean;
    style?: React.CSSProperties;
    onClick?: () => void;
};

export const SanityNextImage: FC<SanityNextImageProps> = ({
    alt,
    sanityImage,
    sizes = '(max-width: 800px) 100vw, 800px',
    className,
    onLoadingComplete = () => null,
    fill = false,
    style,
    onClick = () => {}
}) => {
    const imageProps = useNextSanityImage(client, sanityImage);
    const isRemotion = process.env.IS_REMOTION === 'true';

    if (isRemotion) {
        return imageProps?.src ? (
            <Img
                src={imageProps?.src}
                alt={alt ?? 'Image'}
                className={className}
                style={fill ? { width: '100%', height: '100%', objectFit: 'contain' } : {}}
                onLoad={(e) => {
                    const result = {
                        naturalHeight: e.currentTarget.naturalHeight,
                        naturalWidth: e.currentTarget.naturalWidth
                    };
                    onLoadingComplete(result);
                }}
                onClick={onClick}
            />
        ) : null;
    }

    return (
        <Image
            {...imageProps}
            width={fill ? undefined : imageProps?.width}
            height={fill ? undefined : imageProps?.height}
            fill={fill}
            style={fill ? { objectFit: 'contain' } : style}
            alt={alt ?? 'Image'}
            unoptimized
            className={className}
            onLoadingComplete={(e) => {
                /**
                 * BUG FIX: Firefox has and issue where it is not decoding the naturalHeight and naturalWidth correctly breaking the image, this takes the origing
                 * See: https://github.com/vercel/next.js/issues/34697
                 */
                if (e.naturalHeight === 0 || e.naturalWidth === 0) {
                    e.setAttribute('naturalHeight', imageProps?.height.toString());
                    e.setAttribute('naturalWidth', imageProps?.width.toString());
                    return onLoadingComplete({
                        ...e,
                        naturalHeight: imageProps?.height,
                        naturalWidth: imageProps?.width
                    });
                }
                return onLoadingComplete(e);
            }}
            sizes={sizes}
            onClick={onClick}
        />
    );
};

import { redirect, usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { useGetUserDetails } from 'src/queries/user/useGetUserDetails';
import { useTrackTitanHydratedStore } from 'src/providers/track-titan-store-provider';

export const useRedirectIncompleteUser = () => {
    const pathname = usePathname();
    const { user, isCheckingAuth } = useTrackTitanHydratedStore((state) => state.auth);
    const { data: userDetails } = useGetUserDetails(user?.uuid);

    useEffect(() => {
        if (
            !isCheckingAuth &&
            userDetails &&
            !userDetails?.has_completed_registration &&
            pathname &&
            !pathname.includes('complete-registration')
        ) {
            redirect('/complete-registration');
        }
    }, [userDetails, pathname]);
};

import { sendAnalyticsEvent } from '../utils';

// Fired when intercom is successfully loaded (one time event)

export const intercomLoadedEvent = (email?: string, uuid?: string) =>
    sendAnalyticsEvent({
        uuid,
        email,
        eventName: 'intercom_loaded'
    });

import { useEffect } from 'react';
import { useTrackTitanHydratedStore } from 'src/providers/track-titan-store-provider';
import { useTrackTitanStore } from 'src/zustand/store';
import { getUTMCookies } from 'src/utilities/getUTMCookies';
import { UtmData } from 'src/zustand/app';

export const useUtmData = (utmDataParams: UtmData) => {
    const { setUtmData } = useTrackTitanStore((state) => state.app);
    const { utmData } = useTrackTitanHydratedStore((state) => state.app);

    useEffect(() => {
        const { utm_source, utm_medium, utm_campaign, utm_term, utm_content, utm_referral_code } = utmDataParams;

        if (utm_source || utm_medium || utm_campaign || utm_term || utm_content || utm_referral_code) {
            setUtmData({ ...utmData, utm_source, utm_medium, utm_campaign, utm_term, utm_content, utm_referral_code });
        }
    }, [utmDataParams]);

    useEffect(() => {
        const utmParameters = getUTMCookies();

        if (utmParameters) {
            try {
                const { utm_source, utm_medium, utm_campaign, utm_term, utm_content, utm_referral_code } =
                    utmParameters;
                if (utm_source || utm_medium || utm_campaign || utm_term || utm_content || utm_referral_code) {
                    setUtmData({
                        ...utmData,
                        utm_source,
                        utm_medium,
                        utm_campaign,
                        utm_term,
                        utm_content,
                        utm_referral_code
                    });
                }
            } catch {
                console.error('Error parsing utm cookies');
            }
        }
    }, []);
};

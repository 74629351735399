import { useEffect, useState } from 'react';
import { useTrackTitanHydratedStore } from 'src/providers/track-titan-store-provider';

export function useRedditPixel() {
    const [isInitialised, setIsInitialised] = useState<boolean>(false);
    const { user } = useTrackTitanHydratedStore((state) => state.auth);

    const initialise = () => {
        if (!window.rdt) {
            return;
        }
        window.rdt('init', 't2_8hnceqge', {
            email: user?.email,
            externalId: user?.uuid
        });

        setIsInitialised(true);
    };

    useEffect(() => {
        initialise();
    }, [user]);

    const pageVisit = () => {
        if (!isInitialised) {
            initialise();
        }

        if (window.rdt) {
            window.rdt('track', 'PageVisit');
        }
    };

    const signUp = () => {
        if (!isInitialised) {
            initialise();
        }

        if (window.rdt) {
            window.rdt('track', 'SignUp');
        }
    };

    return { pageVisit, signUp };
}

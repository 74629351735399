import { useQuery } from 'react-query';
import { gaiaApiRequest } from '../utils';
import { checkIsMobileApplication } from 'src/hooks/checkIsMobileApplication';

export interface UserDataResponse {
    pk1: string;
    sk1: string;
    hasLoggedInOnGhost: boolean;
    hasSeenMobileWelcomeMessage: boolean;
    hasLoggedIntoMobileApp: boolean;
    lapsChallengeStatus?: 'accepted' | 'completed';
    lapsChallengeDateAccepted?: number;
    hasSetupPersonalisedNews?: boolean;
}

export interface UserMetaDataResponse {
    pk1: string;
    sk1: string;
    hasLoggedInOnGhost?: boolean;
    hasSeenMobileWelcomeMessage?: boolean;
    hasLoggedIntoMobileApp?: boolean;
    lapsChallengeStatus?: 'accepted' | 'completed';
    lapsChallengeDateAccepted?: number;
    hasSetupPersonalisedNews?: boolean;
}

export const getUserData = async (
    isMobileApplication: boolean,
    uuid?: string
): Promise<UserDataResponse | undefined> => {
    if (!uuid) {
        return undefined;
    }

    try {
        const result = await gaiaApiRequest<UserMetaDataResponse>(`/user/${uuid}/data`);

        return {
            pk1: result.pk1,
            sk1: result.sk1,
            hasLoggedInOnGhost: result.hasLoggedInOnGhost || false,
            hasSeenMobileWelcomeMessage: result.hasSeenMobileWelcomeMessage || false,
            hasLoggedIntoMobileApp: result.hasLoggedIntoMobileApp || isMobileApplication || false,
            lapsChallengeStatus: result.lapsChallengeStatus,
            lapsChallengeDateAccepted: result.lapsChallengeDateAccepted,
            hasSetupPersonalisedNews: result.hasSetupPersonalisedNews
        };
    } catch (error) {
        return {
            pk1: uuid,
            sk1: 'UserMetaData',
            hasLoggedInOnGhost: false,
            hasSeenMobileWelcomeMessage: false,
            hasLoggedIntoMobileApp: isMobileApplication || false,
            hasSetupPersonalisedNews: true
        };
    }
};

export const useGetUserData = (userId?: string, timestamp?: number, refetch = false) => {
    const isMobileApplication = checkIsMobileApplication();

    const query = useQuery(
        ['getUserData', { userId, isMobileApplication, timestamp }],
        () => getUserData(isMobileApplication, userId),
        {
            refetchInterval: refetch ? 10000 : undefined
        }
    );

    return query;
};

import Link from 'next/link';
import Image from 'next/image';

import SideNavGroup from 'components/SideNavGroup';

interface IUnAuthedLinks {
    handleClickAnalytics: (route: string) => () => void;
    isLinkActive: (route?: string) => boolean;
    isCollapsed?: boolean;
}

export const UnAuthedLinks = ({ handleClickAnalytics, isCollapsed = false, isLinkActive }: IUnAuthedLinks) => {
    return (
        <SideNavGroup name="">
            <div className="w-full h-0.5 bg-orange-500 my-2" />
            <Link
                href="/login"
                className={`py-2 flex gap-2 items-center px-6 ${isLinkActive('/login')}`}
                onClick={handleClickAnalytics('login')}
            >
                <Image
                    src="/login-icon.svg"
                    alt="dashboard"
                    width="24"
                    height="24"
                    className="ml-0.5"
                    style={{
                        maxWidth: '100%',
                        height: 'auto'
                    }}
                />
                {!isCollapsed && 'Log in'}
            </Link>
            <Link
                href="/register"
                className={`py-2 flex gap-2 items-center px-6 ${isLinkActive('/register')}`}
                onClick={handleClickAnalytics('register')}
            >
                <Image
                    src="/sign-up-icon.svg"
                    alt="register"
                    width="24"
                    height="24"
                    style={{
                        maxWidth: '100%',
                        height: 'auto'
                    }}
                />
                {!isCollapsed && 'Sign up'}
            </Link>
        </SideNavGroup>
    );
};

import { Amplify } from 'aws-amplify';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { isCurrentRouteInList } from 'src/utilities';
import { useTrackTitanStore } from 'src/zustand/store';
import { useAuthEvents } from './useAuthEvents';
import { useFetchTestUser } from './useFetchTestUser';
import { useGetUsername } from './useGetUsername';
import { useImpersonateUser } from './useImpersonateUser';
import { useTrackTitanHydratedStore } from 'src/providers/track-titan-store-provider';
import { useEvidentlyCheck } from 'src/queries/evidently/useEvidentlyCheck';

export const useAuth = () => {
    const pathname = usePathname();

    const { user, isCheckingAuth, isTestMode } = useTrackTitanHydratedStore((state) => state.auth);
    const { setIsTestMode } = useTrackTitanStore((state) => state.auth);
    const {
        setLastSessionSetup,
        setAchievementCurrentChallenge,
        setVulcanMigration,
        setRemotion,
        setShowCodeOnRegister,
        setRedirectCodeToMainWebsite,
        setNewOnboardingFlow,
        setHymoFeatures
    } = useTrackTitanStore((state) => state.app);

    const isUnAuth = !isCheckingAuth && !user;

    useFetchTestUser();
    useAuthEvents();
    useGetUsername();
    useImpersonateUser();

    useEvidentlyCheck(setLastSessionSetup, 'lastSessionSetup', user);
    useEvidentlyCheck(setAchievementCurrentChallenge, 'achievementCurrentChallenge', user);
    useEvidentlyCheck(setVulcanMigration, 'vulcanMigration', user);
    useEvidentlyCheck(setRemotion, 'remotion', user);
    useEvidentlyCheck(setShowCodeOnRegister, 'showCodeOnRegister', user);
    useEvidentlyCheck(setRedirectCodeToMainWebsite, 'redirectCodeToMainWebsite', user);
    useEvidentlyCheck(setNewOnboardingFlow, 'newOnboardingFlow', user);
    useEvidentlyCheck(setHymoFeatures, 'hymoFeatures', user);

    useEffect(() => {
        if (isUnAuth) {
            setIsTestMode(true);
        }
    }, [isUnAuth]);

    useEffect(() => {
        const testModeRoutes = ['/dashboard', '/sessions', '/session', '/segment'];
        const isTestModePage = isCurrentRouteInList(testModeRoutes, pathname);

        if (!isTestModePage && isTestMode) {
            setIsTestMode(false);
        }
    }, [isTestMode, pathname, user]);

    useEffect(() => {
        if (user) {
            const userId = user.uuid || 'unknown';
            hotjar.identify(userId, { userId });
        }
    }, [user]);

    useEffect(() => {
        Amplify.configure({
            Auth: {
                region: 'us-east-1',
                userPoolId: process.env.NEXT_PUBLIC_POOL_ID,
                userPoolWebClientId: process.env.NEXT_PUBLIC_POOL_CLIENT_ID,
                identityPoolId: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
                oauth: {
                    domain: process.env.NEXT_PUBLIC_COGNITO_DOMAIN,
                    scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
                    redirectSignIn: `${window.location.origin}/oauth/callback`,
                    redirectSignOut: `${window.location.origin}/login`,
                    responseType: 'code'
                }
            }
        });
    }, []);
};

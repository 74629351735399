import axios from 'axios';

// log the pageview with their URL
export const pageview = (url: string) => {
    if (window.gtag) {
        window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS!, {
            page_path: url
        });
    }
};

// log specific events happening.
export const event = ({ action, params }: { action: string; params: Gtag.CustomParams }) => {
    if (window.gtag) {
        window.gtag('event', action, params);
    }
};

export const set = (params: Gtag.CustomParams) => {
    if (window.gtag) {
        window.gtag('set', params);
    }
};

export const getClientId = (): Promise<string> => {
    return new Promise((resolve) => {
        if (window.gtag) {
            window.gtag('get', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS as string, 'client_id', (clientId) => {
                resolve(clientId as string);
            });
        } else {
            resolve('');
        }
    });
};

export const sendClientId = async (userId?: string) => {
    const clientId = await getClientId();

    if (clientId && userId) {
        await axios.patch(`${process.env.NEXT_PUBLIC_GAIA_API}/api/v1/user/${userId}/data?clientId=${clientId}`);
    }
};

import { SanityImage } from 'src/types/sanity';
import { SanityNextImage } from 'components/SanityNextImage';
import { useState } from 'react';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/type';

const gamesWithSetups = ['iRacing', 'assettoCorsa', 'assettoCorsaCompetizione'];

export type SupportedGame = {
    gameId: string;
    image: SanityImage;
    supportedPlatforms: { name: string }[];
    name: string;
    mobileSupported?: boolean;
};

interface ISupportedGames {
    supportedGames?: SupportedGame[];
    mobileOnboarding?: boolean;
}

// try and merge similar platforms

export const SupportedGames = ({ supportedGames, mobileOnboarding }: ISupportedGames) => {
    const [showMoreSupportedTitles, toggleMoreSupportedTitles] = useState(false);
    if (!supportedGames) return null;
    const oldF1Games = ['F12020', 'F12021', 'F12022'];
    const currentSupportedGames = supportedGames.filter((game) => !oldF1Games.includes(game.gameId));
    const oldF1SupportedGames = supportedGames.filter((game) => oldF1Games.includes(game.gameId));

    return (
        <ul>
            {currentSupportedGames.map((game) => {
                const hasSetups = gamesWithSetups.includes(game.gameId);

                return (
                    <li
                        key={game.gameId}
                        className={`flex items-center ${mobileOnboarding ? 'mb-2 text-sm' : 'mb-4 text-base'}`}
                    >
                        <div
                            className={`rounded-full overflow-hidden mr-4 flex-shrink-0 ${
                                mobileOnboarding ? 'w-10' : 'w-12'
                            }`}
                        >
                            <SanityNextImage sanityImage={game.image} alt={game.name} />
                        </div>
                        {game.supportedPlatforms.map((platform) => platform.name).join(', ')}
                        {hasSetups && (
                            <span className="ml-2 text-newOrange font-semibold">(Automatic Setup Installation)</span>
                        )}
                    </li>
                );
            })}
            <li>
                <Button
                    onClick={() => toggleMoreSupportedTitles(!showMoreSupportedTitles)}
                    buttonType={ButtonType.InvertedNoBorder}
                    className=" text-sm w-full my-1"
                >
                    View more supported titles
                </Button>
                {showMoreSupportedTitles && (
                    <ul>
                        {oldF1SupportedGames.map((game) => (
                            <li
                                key={`${game.gameId}`}
                                className={`flex items-center ${mobileOnboarding ? 'mb-2 text-sm' : 'mb-4 text-base'}`}
                            >
                                <div
                                    className={`rounded-full overflow-hidden mr-4 flex-shrink-0 ${
                                        mobileOnboarding ? 'w-10' : 'w-12'
                                    }`}
                                >
                                    <SanityNextImage sanityImage={game.image} alt={game.name} />
                                </div>
                                {game.supportedPlatforms.map((platform) => platform.name).join(', ')}
                            </li>
                        ))}
                    </ul>
                )}
            </li>
        </ul>
    );
};

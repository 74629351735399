import { DesktopComputerIcon } from '@heroicons/react/outline';

import SideNavGroup from 'components/SideNavGroup';
import SideNavItem from 'components/SideNavItem';
import { NewspaperLine } from 'components/Icons/Icons';

interface IRealRacingLinks {
    handleClickAnalytics: (route: string) => () => void;
    isLinkActive: (route?: string) => boolean;
    isCollapsed?: boolean;
    isRaceEngineerActive?: boolean;
}

export const RealRacingLinks = ({
    handleClickAnalytics,
    isLinkActive,
    isCollapsed = false,
    isRaceEngineerActive
}: IRealRacingLinks) => {
    return (
        <SideNavGroup name="Real Racing Insights">
            <SideNavItem link="/news" onClick={handleClickAnalytics('news')} isLinkActive={isLinkActive}>
                <NewspaperLine />
                <span className="xl:inline hidden">{!isCollapsed && 'Motorsport News'}</span>
                <span className="xl:hidden inline">{!isCollapsed && 'News'}</span>
            </SideNavItem>
            {/* <SideNavItem link="/blogs" onClick={handleClickAnalytics('blogs')} isLinkActive={isLinkActive}>
                <Engineering className="w-6 h-6" />
                <span className="xl:inline hidden">{!isCollapsed && 'Engineering Logs'}</span>
                <span className="xl:hidden inline">{!isCollapsed && 'Engineering'}</span>
            </SideNavItem> */}
            <SideNavItem
                link="/race-engineer"
                onClick={handleClickAnalytics('race-engineer')}
                isLinkActive={isLinkActive}
                linkActiveOverride={isRaceEngineerActive}
            >
                <DesktopComputerIcon className="h-6 w-6" />
                {!isCollapsed && 'Race Engineer'}
            </SideNavItem>
        </SideNavGroup>
    );
};

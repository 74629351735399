export const MenuStyles = {
    /* Position and sizing of burger button */
    bmBurgerButton: {
        position: 'relative',
        width: '36px',
        height: '30px',
        right: '0px',
        top: '14px'
    },

    /* Color/shape of burger icon bars */
    bmBurgerBars: {
        background: 'white',
        borderRadius: '4px'
    },

    /* Color/shape of burger icon bars on hover */
    bmBurgerBarsHover: {
        background: '#a90000'
    },

    /* Position and sizing of clickable cross button */
    bmCrossButton: {
        height: '24px',
        width: '24px'
    },

    /* Color/shape of close button cross */
    bmCross: {
        background: '#ffffff'
    },

    /*
        Sidebar wrapper styles
        Note: Beware of modifying this element as it can break the animations
        - you should not need to touch it in most cases
    */
    bmMenuWrap: {
        position: 'fixed',
        height: '100%',
        top: '0'
    },

    /* General sidebar styles */
    bmMenu: {
        background: '#1F2224',
        padding: '2.5em 0 0',
        fontSize: '1em',
        innerWidth: '100px'
    },

    /* Morph shape necessary with bubble or elastic */
    bmMorphShape: {
        fill: '#373a47'
    },

    /* Wrapper for item list */
    bmItemList: {
        color: '#b8b7ad',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },

    /* Individual item */
    bmItem: {
        color: '#ffffff',
        display: 'flex',
        flexDirection: 'column'
    },

    /* Styling of overlay */
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)'
    }
};

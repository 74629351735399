import { vulcanApiRequest } from '../utils';

export type UserDetailType = 'sim_racer' | 'news_user' | 'f1_driver';

export type UserDetails = {
    id: string;
    username: string;
    language: string;
    user_type: UserDetailType;
    has_completed_registration: boolean;
    has_completed_activation: boolean;
    attributes: { [key: string]: any } | null;
};

const defaultUserDetails: Partial<UserDetails> = {
    username: '',
    language: 'en',
    user_type: 'sim_racer',
    has_completed_registration: true,
    has_completed_activation: true,
    attributes: null
};

export const getUserDetails = async (userId?: string): Promise<UserDetails | undefined> => {
    if (!userId) {
        return undefined;
    }
    try {
        const response = await vulcanApiRequest<UserDetails>(`/users/${userId}`);

        if (!response.success) {
            return { ...defaultUserDetails, id: userId } as UserDetails;
        }
        return response.data;
    } catch {
        return { ...defaultUserDetails, id: userId } as UserDetails;
    }
};

interface IProgress {
    currentSlide: number;
    numberOfSlides: number;
}

export const Progress = ({ currentSlide, numberOfSlides }: IProgress) => {
    const dotsComponent = Array.from({ length: numberOfSlides }, (_, i) => i + 1);

    return (
        <div className="flex gap-4 w-max mx-auto">
            {dotsComponent.map((dot) => (
                <div key={dot} className={`w-3 h-3 ${currentSlide < dot ? 'border' : 'bg-success'} rounded-full`} />
            ))}
        </div>
    );
};

import { useQuery } from 'react-query';
import { vulcanApiRequest } from '../utils';

export interface LapsDrivenResponse {
    gameId:
        | 'assettoCorsa'
        | 'assettoCorsaCompetizione'
        | 'F12021'
        | 'F12022'
        | 'F12023'
        | 'F12024'
        | 'forza'
        | 'iRacing';
    count: `${number}`;
}

export const getLapsDrivenCount = async () => {
    const response = await vulcanApiRequest<LapsDrivenResponse[]>(`/platform/laps/count`);

    return response.data;
};

export const useGetLapsDrivenCount = () => {
    // Refresh every 30 mins
    const query = useQuery(['getLapsDrivenCount'], () => getLapsDrivenCount(), { refetchInterval: 30 * 60 * 1000 });

    return query;
};

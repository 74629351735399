import { LapResponse, RawSegmentDataType } from 'src/types';
import { vulcanApiRequest } from '../utils';
import { SessionResponse } from './getSession';
import { SanityGame, SanityTrack } from 'src/types/sanity';
import { VulcanResponse } from '../types';
import { SanityCar } from 'src/types/new';

interface SegmentTelemetry {
    telemetry: {
        user: RawSegmentDataType[];
        reference: RawSegmentDataType[];
    };
    diffToRef: { dist: number; value: number }[];
}

export type LastSessionResponse = {
    session: SessionResponse & LapResponse;
    referenceLap: {
        session_id: string;
        lap_number: number;
        lap_time: string;
    };
    segment: number;
    telemetry: SegmentTelemetry;
    game?: SanityGame;
    track?: SanityTrack;
    car?: SanityCar;
};

export const getLastSession = async (userId?: string) => {
    if (!userId) {
        return undefined;
    }
    try {
        const response = await vulcanApiRequest<LastSessionResponse>(`/sessions/${userId}/last-session`);

        return response;
    } catch (err: any) {
        const response: VulcanResponse<null> = {
            success: false,
            status: err.status,
            message: 'Something went wrong',
            data: null
        };
        return response;
    }
};
getLastSession;

import { Crown } from 'components/Icons/Crown';

interface IPlan {
    isPlusPlan: boolean;
    isPremiumPlan: boolean;
    isUltraPlan: boolean;
    textSmall?: boolean;
}

const Plan = ({ isPlusPlan, isPremiumPlan, isUltraPlan, textSmall = true }: IPlan) => {
    const getText = () => {
        if (isUltraPlan) {
            return 'Ultra';
        }

        if (isPremiumPlan) {
            return 'Premium';
        }

        if (isPlusPlan) {
            return 'Plus';
        }

        return 'Community';
    };

    const getTextColor = () => {
        if (isPremiumPlan) {
            return '#FFD700';
        }

        if (isPlusPlan || isUltraPlan) {
            return '#E0E0E0';
        }

        return '#CD7F32';
    };

    return (
        <div className={`flex ${textSmall ? 'text-sm' : ''} items-center`} style={{ color: getTextColor() }}>
            <Crown className="mr-1" />
            {getText()}
        </div>
    );
};

export default Plan;

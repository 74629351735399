import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useTrackTitanHydratedStore } from 'src/providers/track-titan-store-provider';
import { useGetSessions } from 'src/queries/session/useGetSessions';

export const useRecentActivity = (value: number, unit?: dayjs.ManipulateType | undefined) => {
    const { user } = useTrackTitanHydratedStore((state) => state.auth);

    const { data: sessionsData, isLoading } = useGetSessions(user?.uuid, 1);

    const hasRecentActivity = useMemo(() => {
        const lastSession = sessionsData?.data?.sessions?.[0];

        if (lastSession) {
            const { timestamp } = lastSession;

            // Check if normal ISO timestamp or custom timestamp
            const time = dayjs(timestamp).isValid()
                ? dayjs(timestamp).add(value, unit).unix()
                : dayjs(timestamp, 'hh:mm:ss DD/MM/YYYY').add(value, unit).unix();

            return time > dayjs().unix();
        }

        return false;
    }, [sessionsData]);

    return { hasRecentActivity, sessionsData, isLoading };
};

import { useState } from 'react';

import Button from 'components/Button';
import { ButtonType } from 'components/Button/type';
import { TextInput } from 'components/TextInput';

interface IUserImpersonation {
    impersonationUserId: string;
    onImpersonateUser: (userId: string) => void;
}

const UserImpersonation = ({ impersonationUserId, onImpersonateUser }: IUserImpersonation) => {
    const [userId, setUserId] = useState('');

    const onImpersonateClick = () => {
        onImpersonateUser(userId);
        setUserId('');
    };

    const onResetClick = () => {
        onImpersonateUser('');
        setUserId('');
    };

    return (
        <div className="w-full flex flex-col items-center bg-grey-11 p-2 my-2">
            <h3 className="text-lg pt-2">Impersonate User ID</h3>

            <div className="p-2 text-sm">
                <TextInput label={''} name={''} text={userId} setText={setUserId} />
            </div>

            <div className="flex items-center flex-col xl:flex-row">
                <Button className="mx-1.5 mb-3 xl:mb-0" onClick={onResetClick} buttonType={ButtonType.Blue}>
                    Reset
                </Button>

                <Button className="mx-1.5" onClick={onImpersonateClick}>
                    Impersonate
                </Button>
            </div>

            {impersonationUserId && (
                <p className="p-2 text-sm">
                    Currently impersonating user <br /> {impersonationUserId}
                </p>
            )}
        </div>
    );
};

export default UserImpersonation;

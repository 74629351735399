import { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';

import { UserType } from 'src/types';
import HeaderUpgrade from 'components/HeaderUpgrade';
import { TopLinks } from 'components/SideNav/TopLinks';
import { SimracingLinks } from 'components/SideNav/SimracingLinks';
import { RealRacingLinks } from 'components/SideNav/RealRacingLinks';
import { HelpSupportLinks } from 'components/SideNav/HelpSupportLinks';
import { UnAuthedLinks } from 'components/SideNav/UnAuthedLinks';
import { burgerMenuClickedEvent } from 'src/analytics/layout/burgerMenuClicked';
import { UserLinks } from 'components/SideNav/UserLinks';
import { useIsLinkActive } from 'src/utilities/isLinkActive';

import * as S from './styles';
import { useRaceEngineerActive } from 'src/hooks/useRaceEngineerActive';

interface IBurgerMenu {
    user?: UserType;
    authSignOut: () => Promise<void>;
    openOnboarding: () => void;
}

export const BurgerMenu = ({ user, authSignOut, openOnboarding }: IBurgerMenu) => {
    const [isOpen, setIsOpen] = useState(false);

    const closeMenu = () => setIsOpen(false);
    const handleStateChange = (state: { isOpen: boolean }): void => setIsOpen(state.isOpen);

    const isLinkActive = useIsLinkActive();
    const isRaceEngineerActive = useRaceEngineerActive();

    const hideUpgradeButton =
        (user?.isTrial && user.dateTimeTrialEnds) ||
        user?.isPremiumPlan ||
        (user?.isPlusPlan && !user.isTrial) ||
        (user?.isUltraPlan && !user.isTrial);

    const handleClickAnalytics = (route: string) => () => {
        closeMenu();
        burgerMenuClickedEvent(route, user?.email, user?.uuid);
    };

    const menuSignOut = async (): Promise<void> => {
        try {
            await authSignOut();
        } catch (error) {
            console.log({ error });
        }

        closeMenu();
    };

    return (
        <Menu
            isOpen={isOpen}
            onStateChange={handleStateChange}
            styles={S.MenuStyles}
            disableAutoFocus
            right
            width={240}
        >
            <div className="flex flex-col gap-6">
                <TopLinks isCollapsed={false} isLinkActive={isLinkActive} handleClickAnalytics={handleClickAnalytics} />

                <SimracingLinks
                    isLinkActive={isLinkActive}
                    handleClickAnalytics={handleClickAnalytics}
                    isLoggedIn={!!user}
                    user={user}
                    isRaceEngineerActive={isRaceEngineerActive}
                />

                <RealRacingLinks
                    isLinkActive={isLinkActive}
                    handleClickAnalytics={handleClickAnalytics}
                    isRaceEngineerActive={isRaceEngineerActive}
                />

                <HelpSupportLinks
                    isLinkActive={isLinkActive}
                    handleClickAnalytics={handleClickAnalytics}
                    openOnboarding={openOnboarding}
                    isLoggedIn={!!user}
                    user={user}
                    mobile
                    closeNav={closeMenu}
                />
                {!user && <UnAuthedLinks isLinkActive={isLinkActive} handleClickAnalytics={handleClickAnalytics} />}
                {user && (
                    <UserLinks
                        isLinkActive={isLinkActive}
                        handleClickAnalytics={handleClickAnalytics}
                        onSignOut={menuSignOut}
                        user={user}
                    />
                )}
                <div className="flex flex-row justify-center">
                    {user && !hideUpgradeButton && <HeaderUpgrade user={user} classNames="mb-2" />}
                </div>
            </div>
        </Menu>
    );
};

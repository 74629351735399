import { vulcanApiRequest } from '../utils';

interface EvidentlyCheckResponse {
    experimentName: string;
    userId: string;
    result: boolean;
}

export const evidentlyCheck = async (experimentName: string, userId?: string, email?: string, defaultValue = false) => {
    if (!userId) return defaultValue;

    try {
        const response = await vulcanApiRequest<EvidentlyCheckResponse>(
            `/evidently/experiments/${experimentName}/users/${userId}`
        );

        return response?.data?.result ?? defaultValue;
    } catch (e) {
        console.log(e);
        return defaultValue;
    }
};

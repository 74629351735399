import { Auth } from 'aws-amplify';
import toast from 'react-hot-toast';
import { useTrackTitanStore } from 'src/zustand/store';
import Cookies from 'js-cookie';
import { useTrackTitanHydratedStore } from 'src/providers/track-titan-store-provider';

export const useSignOut = () => {
    const { setImpersonationUserId, setUser, setIsCheckingAuth } = useTrackTitanStore((state) => state.auth);
    const { mobileAppSource } = useTrackTitanHydratedStore((state) => state.auth);

    const handleSignOut = async () => {
        setIsCheckingAuth(true);

        try {
            await Auth.signOut();

            const dummyAccountId = process.env.NEXT_PUBLIC_DUMMY_ACCOUNT_UUID;
            Cookies.set('tt-user-id', dummyAccountId ?? '', { expires: 365, sameSite: 'strict' });

            setImpersonationUserId('');
            setUser(undefined);
            if (mobileAppSource) {
                mobileAppSource.postMessage({ AUTH_LOGOUT: true });
            }
        } catch (error) {
            toast.error('Something went wrong signing out');
        }

        setIsCheckingAuth(false);
    };

    return handleSignOut;
};

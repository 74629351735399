export const Crown = ({ className, fill = false }: { className: string; fill?: boolean }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M12 6l4 6l5 -4l-2 10h-14l-2 -10l5 4z" fill={fill ? 'currentColor' : 'none'} />
    </svg>
);

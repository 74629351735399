import { useEffect } from 'react';
import { sendClientId } from 'src/analytics/utils/google';
import { useTrackTitanStore } from 'src/zustand/store';

export const useSendClientId = () => {
    const { user } = useTrackTitanStore((state) => state.auth);

    useEffect(() => {
        if (user) {
            sendClientId(user.uuid);
        }
    }, [user]);
};

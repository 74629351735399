import { UserType } from 'src/types';
import { BenefitsResponse } from 'src/types/new';

export const getUserBenefitData = async (userId: string): Promise<Partial<UserType>> => {
    // database data
    const response = await fetch(`${process.env.NEXT_PUBLIC_GAIA_API}/api/v1/user/${userId}/benefits`);

    const benefitsData = (await response.json()) as BenefitsResponse;

    const user: Partial<UserType> = {};
    user.isPlusPlan = false;
    user.isSetupSubscriptionPlan = false;
    // TODO: this logic needs to be refactored when Premium plan does more than paywall
    if (benefitsData.benefits.find((benefit) => benefit.name === 'basicTier')) {
        user.isPlusPlan = true;

        const benefit = benefitsData.benefits[0];

        if (benefit.metadata && 'isTrial' in benefit.metadata) {
            user.isTrial = benefit.metadata.isTrial;
            user.dateTimeTrialEnds = benefit.metadata.dateTimeTrialEnds;
        }
    }

    if (benefitsData.benefits.find((benefit) => benefit.name === 'premiumTier')) {
        user.isPremiumPlan = true;
        user.isPlusPlan = false;
    }

    if (benefitsData.benefits.find((benefit) => benefit.name === 'ultraTier')) {
        user.isUltraPlan = true;
        user.isPremiumPlan = false;
        user.isPlusPlan = false;
    }

    if (benefitsData.benefits.find((benefit) => benefit.name === 'setupSubscriptionTier')) {
        user.isSetupSubscriptionPlan = true;
    }

    return user;
};

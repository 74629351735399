import { sendAnalyticsEvent } from '../utils';

// Fired when one of the side nav menu items is clicked, the route name of the item is passed

export const sideNavClickedEvent = (route: string, email?: string, uuid?: string) =>
    sendAnalyticsEvent({
        uuid,
        email,
        eventName: `header_${route}_clicked`
    });

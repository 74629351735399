import { ReactNode } from 'react';

interface ISideNavGroup {
    name: string;
    children: ReactNode;
}

const SideNavGroup = ({ name, children }: ISideNavGroup) => {
    return (
        <div>
            <p className="uppercase text-sm text-grey-25 px-6">{name}</p>
            <div className="flex flex-col">{children}</div>
        </div>
    );
};

export default SideNavGroup;

import { UserType } from 'src/types';

const unauthOnlyRoutes = ['/confirm', '/forgot-password', '/reset-password', '/login', '/register', '/oauth/callback'];
const authOrUnauthRoutes = [
    '/pricing',
    '/dashboard',
    '/session',
    '/sessions',
    '/segment',
    '/leaderboards',
    '/academy',
    '/setups',
    '/setup',
    '/profile',
    '/news',
    '/race-engineer',
    '/blog',
    '/track-guide'
];

const unAuthRoutes = [...unauthOnlyRoutes, ...authOrUnauthRoutes];

type Params = {
    user: UserType | undefined;
    pathname: string;
    savedRoute: string;
    setSavedRoute: (route: string) => void;
    setupReferral: string;
    setSetupReferral: (route: string) => void;
};

export const redirectRoute = ({
    user,
    pathname,
    setSavedRoute,
    savedRoute,
    setupReferral,
    setSetupReferral
}: Params): string | null => {
    const route = pathname;

    const isUnauthRoute = unauthOnlyRoutes.some((unAuthRoute) => route.includes(unAuthRoute));
    const isProtectedRoute = !unAuthRoutes.some((unAuthRoute) => route.includes(unAuthRoute));

    // redirect to login but save the route for later
    if (!user && isProtectedRoute) {
        if (pathname.includes('quiz-duel') && !pathname.endsWith('quiz-duel')) {
            return null;
        }

        if (route.startsWith('/pod') && pathname.endsWith('join')) {
            setSavedRoute(route);
            return '/login';
        }
        if (!pathname.includes('/complete-registration')) setSavedRoute(route);
        return '/login';
    }

    // Just save the route for later
    if (!user && !isUnauthRoute) {
        setSavedRoute(route);
        return null;
    }

    // If signup page then go to complete registration otherwise, don't route
    if (user && pathname.includes('/register')) {
        return '/complete-registration';
    }

    if (user && pathname.includes('/complete-registration')) {
        return null;
    }

    // Reset the route
    if (user && setupReferral) {
        const path = setupReferral;
        setSavedRoute('/');
        setSetupReferral('');
        return path;
    }

    if (user && savedRoute !== '/') {
        setSavedRoute('/');
        return savedRoute;
    }

    if (user && unauthOnlyRoutes.includes(route)) {
        setSavedRoute('/');
        return '/dashboard';
    }

    return null;
};

import Image from 'next/image';

import { Rocket } from 'components/Icons/Rocket';
import { FirstAidKitLine } from 'components/Icons/Icons';
import SideNavGroup from 'components/SideNavGroup';
import SideNavItem from 'components/SideNavItem';
import { useHelpLink } from 'src/hooks/useHelpLink';
import { ActiveDot } from '../SimracingLinks/ActiveDot';
import { useGetSessions } from 'src/queries/session/useGetSessions';
import { UserType } from 'src/types';

interface IHelpSupportLinks {
    handleClickAnalytics: (route: string) => () => void;
    isLinkActive: (route?: string) => boolean;
    isCollapsed?: boolean;
    openOnboarding: () => void;
    isLoggedIn: boolean;
    user?: UserType;
    mobile?: boolean;
    closeNav?: () => void;
}

export const HelpSupportLinks = ({
    handleClickAnalytics,
    isLinkActive,
    isCollapsed = false,
    openOnboarding,
    isLoggedIn,
    user,
    mobile = false,
    closeNav = () => {}
}: IHelpSupportLinks) => {
    const handleHelpClick = useHelpLink(handleClickAnalytics);
    const { data: sessionsData } = useGetSessions(user?.uuid);

    return (
        <SideNavGroup name="Help / Setup">
            {isLoggedIn && !mobile && (
                <button
                    type="button"
                    onClick={openOnboarding}
                    className="py-1 flex gap-2 items-center px-6 relative w-full  bg-grey-05 hover:bg-grey-11 active:bg-grey-15 transition-all duration-300"
                >
                    <Image
                        src="/download.svg"
                        alt="download"
                        width="24"
                        height="24"
                        style={{
                            maxWidth: '100%',
                            height: 'auto'
                        }}
                    />
                    {!isCollapsed && 'Desktop App'}
                    {sessionsData && !sessionsData?.data?.sessions.length && <ActiveDot />}
                </button>
            )}

            {isLoggedIn && mobile && (
                <button
                    type="button"
                    onClick={() => {
                        handleClickAnalytics('mobile_onboarding');
                        closeNav();
                        openOnboarding();
                    }}
                    className="py-1 flex gap-2 items-center px-6 relative w-full  bg-grey-05 hover:bg-grey-11 active:bg-grey-15 transition-all duration-300"
                >
                    <Image
                        src="/download.svg"
                        alt="download"
                        width="24"
                        height="24"
                        style={{
                            maxWidth: '100%',
                            height: 'auto'
                        }}
                    />
                    {!isCollapsed && 'Set Up'}
                    {sessionsData && !sessionsData?.data?.sessions.length && <ActiveDot />}
                </button>
            )}
            <SideNavItem link="/pricing" onClick={handleClickAnalytics('pricing')} isLinkActive={isLinkActive}>
                <Rocket className="w-6 h-6" />
                {!isCollapsed && 'Memberships'}
            </SideNavItem>
            <SideNavItem
                externalLink={true}
                link="https://intercom.help/track-titan"
                onClick={handleHelpClick}
                isLinkActive={isLinkActive}
            >
                <FirstAidKitLine />
                {!isCollapsed && 'Support'}
            </SideNavItem>
        </SideNavGroup>
    );
};

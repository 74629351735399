import { useEffect } from 'react';

export const useRemoveOldLocalStorage = () => {
    useEffect(() => {
        try {
            if (typeof window !== 'undefined') {
                window.localStorage.removeItem('email');
                window.localStorage.removeItem('password');
            }
        } catch (e) {
            console.log({ e });
        }
    }, []);
};

import React, { useEffect } from 'react';
import Image from 'next/image';
import { Img } from 'remotion';

import styles from './index.module.css';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/type';
import { Edit } from 'components/Icons/Edit';

interface IProfileCardImage {
    username: string;
    hasProfilePic: boolean;
    setHasProfilePic: (hasProfilePic: boolean) => void;
    userId?: string;
    className?: string;
    dimension?: number;
    size?: 'small' | 'medium' | 'large';
    timestamp: number;
    isProfile?: boolean;
    showEditButton?: boolean;
    onEditClick?: () => void;
    imageOverride?: string;
    borderColour?: string;
}

const colors = ['#16A34A', '#DC2626', '#2563EB', '#CA8A04', '#0891B2', '#9333EA', '#65A30D', '#BE185D'];

const ProfileCardImage = ({
    username,
    hasProfilePic,
    setHasProfilePic,
    userId,
    className,
    dimension = 40,
    size = 'medium',
    timestamp,
    showEditButton = false,
    onEditClick,
    imageOverride,
    borderColour
}: IProfileCardImage) => {
    useEffect(() => {
        setHasProfilePic(true);
    }, [timestamp]);

    const colorOfPicture = username.charCodeAt(0) % colors.length;
    const border = borderColour ? `${borderColour} border-2` : '';

    return (
        <div
            className={styles.ProfileCardImageContainer}
            style={{ width: `${dimension + 4}px`, height: `${dimension + 4}px` }}
        >
            {hasProfilePic || imageOverride ? (
                <div
                    className={`rounded-full relative ${border}`}
                    style={{
                        width: `${dimension + 4}px`,
                        height: `${dimension + 4}px`
                    }}
                >
                    {process.env.IS_REMOTION === 'true' ? (
                        <Img
                            src={`${process.env.REMOTION_NEXT_PUBLIC_PROFILE_PICTURE_URL}/${userId}_${size}.webp?timestamp=${timestamp}`}
                            style={{ height: dimension, width: dimension }}
                            className="rounded-full object-contain"
                        />
                    ) : (
                        <Image
                            src={
                                imageOverride
                                    ? imageOverride
                                    : `${process.env.NEXT_PUBLIC_PROFILE_PICTURE_URL}/${userId}_${size}.webp?timestamp=${timestamp}`
                            }
                            alt={`${username}'s profile picture`}
                            onError={() => {
                                setHasProfilePic(false);
                            }}
                            width={dimension}
                            height={dimension}
                            className="rounded-full object-contain"
                            unoptimized
                            style={{
                                maxWidth: '100%',
                                height: 'auto'
                            }}
                        />
                    )}
                </div>
            ) : (
                <div
                    className={`rounded-full text-white flex items-center justify-center font-cairo ${className} flex items-center justify-center ${
                        border ?? 'border-white'
                    }`}
                    style={{ backgroundColor: colors[colorOfPicture] }}
                >
                    {username.charAt(0).toUpperCase()}
                </div>
            )}

            {showEditButton && onEditClick && (
                <div className={styles.ProfileImageEdit} onClick={onEditClick}>
                    <Button buttonType={ButtonType.Blue} className="mt-10">
                        <Edit />
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ProfileCardImage;

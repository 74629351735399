import { FC } from 'react';
import Image from 'next/image';
import wheel from 'src/assets/moza-wheel.png';
import Tooltip from 'react-tooltip';
import { Img, staticFile } from 'remotion';

interface IWheel {
    fillColor: string;
    className?: string;
    angle?: number;
    onHover: () => void;
}

export const Wheel = ({ className, fillColor, angle, onHover }: IWheel) => {
    const id = `wheel-tooltip-${fillColor}`;

    return (
        <div className="p-0.5 xl:p-1 h-full flex items-center justify-center">
            <div
                className={`w-full h-auto md:w-auto md:h-full ${className} rounded-full aspect-square flex items-center justify-center`}
                style={{
                    transform: `rotate(${angle || 0}deg)`,
                    transition: 'transform .3s',
                    maxHeight: '90px',
                    filter: `drop-shadow(0px 0px 3px ${fillColor})`
                }}
                data-tip={'MOZA Racing CS Steering Wheel'}
                data-for={id}
                onMouseEnter={() => {
                    onHover();
                }}
            >
                {process.env.IS_REMOTION === 'true' ? (
                    <Img
                        src={staticFile('moza-wheel.png')}
                        alt={`Steering wheel`}
                        style={{
                            maxWidth: '100%',
                            height: 'auto'
                        }}
                    />
                ) : (
                    <Image
                        src={wheel}
                        alt={`Steering wheel`}
                        style={{
                            maxWidth: '100%',
                            height: 'auto'
                        }}
                    />
                )}
            </div>
            <Tooltip className="whitespace-pre" id={id} />
        </div>
    );
};

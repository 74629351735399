import { TrackType, trackTypes, useFBQTrack } from './facebook';
import { event } from './google';
import { useRedditPixel } from './reddit';

export const useTracking = () => {
    const { track, trackCustom } = useFBQTrack();
    const { signUp, pageVisit } = useRedditPixel();

    const trackEvent = (params: { trackEvent: TrackType | string }, options?: any) => {
        event({
            action: params.trackEvent,
            params: options
        });

        if (trackTypes.includes(params.trackEvent as TrackType)) {
            track(params as { trackEvent: TrackType }, options);
        } else {
            trackCustom(params, options);
        }

        if (params.trackEvent === 'CompleteRegistration') {
            signUp();
        }

        if (params.trackEvent === 'PageView') {
            pageVisit();
        }
    };

    return trackEvent;
};

import { useQuery } from 'react-query';
import { vulcanApiRequest } from '../utils';
import { VulcanResponse } from '../types';

export interface UserPointsResponse {
    points: number;
}

export const getUserPoints = async (
    uuid?: string,
    total?: boolean
): Promise<VulcanResponse<UserPointsResponse> | undefined> => {
    if (!uuid) {
        return { success: true, status: 200, data: { points: 0 }, message: '' };
    }

    return vulcanApiRequest<UserPointsResponse>(`/users/${uuid}/points${total ? '/total' : ''}`);
};

export const useGetUserPoints = (userId?: string, total = false) => {
    const query = useQuery(['getUserPoints', { userId, total }], () => getUserPoints(userId, total));

    return query;
};

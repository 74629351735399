import { useEffect } from 'react';
import { intercomLoadedEvent } from 'src/analytics/intercom/intercomLoaded';
import { intercomNotLoadedEvent } from 'src/analytics/intercom/intercomNotLoaded';
import { useTrackTitanHydratedStore } from 'src/providers/track-titan-store-provider';
import { useTrackTitanStore } from 'src/zustand/store';

let useIntercomLoaded = () => {
    const { user } = useTrackTitanHydratedStore((state) => state.auth);
    const { hasSentIntercomLoadedEvent } = useTrackTitanHydratedStore((state) => state.storage);
    const { setHasSentIntercomLoadedEvent } = useTrackTitanStore((state) => state.storage);

    useEffect(() => {
        let timeout: number | undefined;

        if (!hasSentIntercomLoadedEvent) {
            timeout = setTimeout(() => {
                // @ts-ignore
                if (typeof Intercom !== 'undefined') {
                    intercomLoadedEvent(user?.email, user?.uuid);
                } else {
                    intercomNotLoadedEvent(user?.email, user?.uuid);
                }
                setHasSentIntercomLoadedEvent(true);
            }, 10000) as unknown as number;
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [user?.uuid]);
};

if (process.env.NEXT_PUBLIC_MOBILE_APP === 'true' || process.env.MOBILE_APP === 'true') {
    useIntercomLoaded = () => {};
}

export { useIntercomLoaded };

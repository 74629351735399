import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { usePrevious } from 'react-use';

export const useOnNavigationChange = (onChange: (url: string) => void) => {
    const pathname = usePathname();
    const prevPathname = usePrevious(pathname);

    useEffect(() => {
        if (pathname && pathname !== prevPathname) {
            onChange(pathname);
        }
    }, [pathname, prevPathname]);
};

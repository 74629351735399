import { Auth } from 'aws-amplify';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { useTrackTitanStore } from 'src/zustand/store';
import { getUser } from './auth/useAuthEvents';

export const useEventDrivenRouter = () => {
    const router = useRouter();
    const { user, setUser, setUserCredentials, setIsCheckingAuth } = useTrackTitanStore((state) => state.auth);

    const lister = async (event: MessageEvent) => {
        if (event.data.type === 'changePage' && event.data.route) {
            router.replace(event.data.route);
        }
        if (event.data.type === 'checkAuth') {
            // Generate a new session with keys in localStorage (Added manually via the native app)
            await Auth.currentSession();
            // Propagate the information into our state
            await getUser(setUser, setUserCredentials, setIsCheckingAuth);
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('message', lister);
            if (typeof window !== 'undefined') window.document.body.classList.add('navigationListenerReady');

            return () => {
                window.removeEventListener('message', lister);

                if (typeof window !== 'undefined') window.document.body.classList.remove('navigationListenerReady');
            };
        }
    }, []);
};

import React from 'react';
import styles from './index.module.css';
import { PortableText } from '@portabletext/react';
import Modal from 'components/Modal';
import { SanityNextImage } from 'components/SanityNextImage';

interface IInfoModal {
    _id: string;
    title: string;
    content: any[];
    onClose: (id: string) => void;
}

const InfoModal = ({ _id, title, content, onClose }: IInfoModal) => {
    return (
        <Modal open closeModal={() => onClose(_id)} maxWidth="32rem">
            <div className={styles.InfoModalContainer}>
                <PortableText
                    value={content}
                    components={{
                        types: {
                            image: ({ value }) => (
                                <div
                                    style={{
                                        height: value.height ?? 'auto'
                                    }}
                                    className="flex items-center justify-center relative w-full"
                                >
                                    <SanityNextImage sanityImage={value.asset} fill />
                                </div>
                            )
                        }
                    }}
                />
            </div>
        </Modal>
    );
};

export default InfoModal;

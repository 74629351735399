import Link from 'next/link';
import React, { MouseEvent, ReactNode } from 'react';
import styles from './index.module.css';

interface ISideNavItem {
    link?: string;
    onClick: (evt: MouseEvent<HTMLAnchorElement>) => void;
    isLinkActive: (route?: string) => boolean;
    children: ReactNode;
    className?: string;
    externalLink?: boolean;
    linkActiveOverride?: boolean;
}

const SideNavItem = ({
    link,
    onClick,
    isLinkActive,
    children,
    className = '',
    externalLink,
    linkActiveOverride
}: ISideNavItem) => {
    const isActive = linkActiveOverride ?? isLinkActive(link);

    const activeClass = 'bg-orange-500';
    const defaultClass = 'bg-grey-05 hover:bg-grey-11 active:bg-grey-15';
    const bgClassName = isActive ? activeClass : defaultClass;

    if (externalLink || !link) {
        return (
            <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className={`${styles.SideNavItemLink} ${bgClassName} ${className}`}
                onClick={onClick}
            >
                {children}
            </a>
        );
    }

    return (
        <Link href={link} className={`${styles.SideNavItemLink} ${bgClassName} ${className}`} onClick={onClick}>
            {children}
        </Link>
    );
};

export default SideNavItem;

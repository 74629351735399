import { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import { SupportedGame } from 'components/SupportedGames';
import { Games } from './screens/Games';
import { AppDownload } from './screens/AppDownload';
import { ArrowBack, Onboarding } from 'components/Icons/Icons';
import { DesktopLogger } from './screens/DesktopLogger';
import { onboardingGameSelectedEvent } from 'src/analytics/layout/onboardingGameSelected';

interface IMobileOnboarding {
    supportedGames: SupportedGame[];
    onEmailClick: () => void;
    onboardingEmailHasBeenSent: boolean;
    emailLoading?: boolean;
    buttonClassName?: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    userId: string;
    gameSelectionGame?: SupportedGame;
    email?: string;
}

const MobileOnboarding = ({
    supportedGames,
    onEmailClick,
    onboardingEmailHasBeenSent,
    emailLoading,
    buttonClassName = '',
    open,
    setOpen,
    userId,
    gameSelectionGame,
    email
}: IMobileOnboarding) => {
    const [screen, setScreen] = useState<'games' | 'appDownload' | 'desktopLogger'>('games');
    const [activeGame, setActiveGame] = useState<SupportedGame | undefined>(gameSelectionGame);

    useEffect(() => {
        if (gameSelectionGame) {
            setActiveGame(gameSelectionGame);
            onGameClick(gameSelectionGame);
        }
    }, [gameSelectionGame]);

    const onBack = () => {
        setActiveGame(undefined);
        setScreen('games');
    };

    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);

    const onGameClick = (game: SupportedGame) => {
        setActiveGame(game);
        onboardingGameSelectedEvent(game.gameId, email, userId);

        if (game.mobileSupported && game.gameId.includes('F120')) {
            setScreen('appDownload');
        } else {
            setScreen('desktopLogger');
        }
    };

    if (!open) {
        return (
            <div className={buttonClassName}>
                <button
                    onClick={onOpen}
                    className="bg-orange-500 border-2 border-white p-3 rounded-full text-grey-05 hover:scale-105 active:scale-105 transition-all"
                >
                    <Onboarding className="w-8 h-8" />
                </button>
            </div>
        );
    }

    return (
        <Modal open={open} closeModal={onClose} maxWidth="400px" controlIntercom={true}>
            {screen !== 'games' && (
                <button
                    type="button"
                    onClick={onBack}
                    className="absolute top-2 left-2 hover:bg-grey-20 transition-all duration-300 p-1 rounded-full h-8 w-8 flex items-center justify-center"
                >
                    <ArrowBack />
                </button>
            )}

            {screen === 'games' && <Games supportedGames={supportedGames} onGameClick={onGameClick} />}
            {screen === 'appDownload' && activeGame && <AppDownload activeGame={activeGame} />}
            {screen === 'desktopLogger' && (
                <DesktopLogger
                    supportedGames={supportedGames}
                    onEmailClick={onEmailClick}
                    onboardingEmailHasBeenSent={onboardingEmailHasBeenSent}
                    emailLoading={emailLoading}
                />
            )}
        </Modal>
    );
};

export default MobileOnboarding;

import { SanityImage } from 'src/types/sanity';
import { sanityQuery } from '../utils';

export const fetchImage = (imageName: string) => {
    const query = `*[_type == 'miscImages' && name == '${imageName}'][0]{
        image
    }`;

    return sanityQuery<{ image: SanityImage }>(query);
};

import React, { useMemo } from 'react';
import styles from './index.module.css';

interface BasicModal {
    open: boolean;
    component: React.ReactNode;
}

interface IModalManager {
    modals: BasicModal[];
}

const ModalManager = ({ modals }: IModalManager) => {
    const openModals = useMemo(() => {
        return modals
            .map((modal, i) => ({ ...modal, priority: i }))
            .filter((modal) => modal.open)
            .sort((a, b) => a.priority - b.priority);
    }, [modals]);

    if (!openModals.length) return null;

    const openModal = openModals[0];

    return <>{openModal.component}</>;
};

export default ModalManager;

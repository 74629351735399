import { usePathname } from 'next/navigation';

export const useIsLinkActive = () => {
    const pathname = usePathname();

    const isLinkActive = (route?: string) => {
        if (!route || !pathname) return false;
        if (pathname.includes(route)) return true;
        if (route === '/sessions' && (pathname.includes('/session') || pathname.includes('/segment'))) {
            return true;
        }
        return false;
    };

    return isLinkActive;
};

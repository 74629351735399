import React, { useMemo, useRef, useState } from 'react';
import { SupportedGame } from 'components/SupportedGames';
import { SanityNextImage } from 'components/SanityNextImage';
import { SanityImage } from 'src/types/sanity';
import { RacingHelmet } from 'components/Icons/RacingHelmet';
import styles from './index.module.css';
import { useHoverDirty } from 'react-use';
import { LapsDrivenResponse } from 'src/queries/stats/getLapDrivenCount';

const getDriversOnlineAlignment = (isTabletOrLarger: boolean, isUnauth: boolean): 'left' | 'right' | 'center' => {
    if (!isTabletOrLarger && isUnauth) return 'center';
    if (isUnauth) return 'right';

    return isTabletOrLarger ? 'right' : 'left';
};

interface IProcessedGame {
    gameId: string;
    image: SanityImage;
    name: string;
    count: number;
}

interface LapCountProps {
    lapCountResponse: LapsDrivenResponse[] | null | undefined;
    games: SupportedGame[];
    isTabletOrLarger: boolean;
    isUnauth: boolean;
}

const LapsDriven = ({ lapCountResponse, games, isTabletOrLarger, isUnauth }: LapCountProps) => {
    const [open, setOpen] = useState(false);
    const hoverRef = useRef<HTMLDivElement>(null);
    const isHovering = useHoverDirty(hoverRef);

    const align = getDriversOnlineAlignment(isTabletOrLarger, isUnauth);

    const data = games
        .map((game) => {
            const data = lapCountResponse?.find((count) => count.gameId === game.gameId);

            if (!data) return null;

            return {
                gameId: game.gameId,
                image: game.image,
                name: game.name === 'Assetto Corsa Competizione' ? 'ACC' : game.name,
                count: Number(data.count)
            };
        })
        .filter((data) => data) as IProcessedGame[];

    const tableRows = data
        .filter((game) => !!game.count)
        .sort((a, b) => {
            if (a.count > b.count) {
                return -1;
            }
            if (b.count > a.count) {
                return 1;
            }
            return 0;
        })
        .map((game) => {
            return (
                <div className="flex items-center border-t text-xs" key={game.gameId}>
                    <div className="flex items-center" style={{ flex: 2 }}>
                        <div className="w-8 h-8 rounded-lg mr-2 m-1 overflow-hidden flex-shrink-0">
                            <SanityNextImage sanityImage={game.image} alt={game.name} />
                        </div>
                        <p>{game.name === 'ACC' ? 'Assetto Corsa Competizione' : game.name}</p>
                    </div>

                    <div className="text-center" style={{ flex: 1 }}>
                        <div>{game.count.toLocaleString()}</div>
                    </div>
                </div>
            );
        });

    const onTagClick = () => {
        if (!isTabletOrLarger) {
            setOpen(!open);
        }
    };

    const lapsDrivenTodayNow = useMemo(() => {
        const overallLapsDriven = lapCountResponse
            ?.map((counts) => Number(counts.count))
            .reduce((acc, prev) => acc + prev, 0);

        return overallLapsDriven;
    }, [lapCountResponse]);

    return (
        <div className="relative">
            <div className={`${styles.LapsDrivenTag} ${isHovering || open ? 'bg-grey-15' : ''}`} onClick={onTagClick}>
                <div className="flex items-center justify-center">
                    <RacingHelmet className="mr-2" />
                    <p className="">{lapsDrivenTodayNow?.toLocaleString()}</p>
                </div>
                <p className="hidden md:block text-sm text-gray-400">Laps driven today</p>
            </div>

            {tableRows.length > 0 && (
                <div
                    className={`${styles.LapsDrivenTable} ${open ? 'flex' : styles.LapsDrivenTableHidden} ${
                        align === 'left'
                            ? '-left-20 md:left-0'
                            : align === 'right'
                            ? 'right-0'
                            : styles.LapsDrivenTableCenter
                    }`}
                    ref={hoverRef}
                >
                    <div className="p-2 px-4 pb-1 w-full">
                        <div className="flex font-bold text-xs">
                            <div className="flex items-end mb-1" style={{ flex: 2 }}>
                                Game
                            </div>

                            <div className="flex items-end mb-1 w-full justify-center" style={{ flex: 1 }}>
                                Laps driven today
                            </div>
                        </div>

                        {tableRows}
                    </div>
                </div>
            )}
        </div>
    );
};

export default LapsDriven;

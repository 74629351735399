import { useQuery } from 'react-query';
import { vulcanApiRequest } from '../utils';
import { VulcanResponse } from '../types';

export interface LapUsageResponse {
    membershipBenefits: { name: string; metaData: Record<string, unknown> };
    lapsRecorded?: number;
    lapUsageLimit?: number;
    usageReset?: number;
}

export const getLapUsage = async (uuid?: string): Promise<VulcanResponse<LapUsageResponse> | undefined> => {
    if (!uuid) {
        return undefined;
    }

    return vulcanApiRequest<LapUsageResponse>(`/users/${uuid}/laps/usage`);
};

// Refetch every 2 mins
export const useGetLapUsage = (userId?: string, refetchInterval: number = 120000) => {
    const query = useQuery(['getLapUsage', { userId }], () => getLapUsage(userId), {
        refetchInterval
    });

    return query;
};

import { useQuery } from 'react-query';
import { vulcanAuthApiRequest } from '../utils';
import { UserType } from 'src/types';
import { getUserDetails } from './getUserDetails';

export const useGetUserDetails = (userId?: string) => {
    const query = useQuery(['getUserDetails', { userId }], () => getUserDetails(userId));

    return query;
};

export const changeUserAttribute = async ({
    user,
    newAttribute
}: {
    user?: UserType;
    newAttribute: { [attribute: string]: any };
}) => {
    if (!user || !user.auth || !newAttribute) return undefined;
    const authToken = user.auth.accessToken;
    const authTokenExpiresAt = user.auth.accessTokenExpiresAt;
    const response = await vulcanAuthApiRequest(`/users/${user.uuid}`, authToken, authTokenExpiresAt, {
        method: 'patch',
        data: {
            id: user.uuid,
            attributes: newAttribute
        }
    });
    return response;
};

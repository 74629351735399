import { useCallback, MouseEvent } from 'react';

export const useHelpLink = (handleClickAnalytics?: (route: string) => () => void) => {
    return useCallback((evt: MouseEvent<HTMLAnchorElement>) => {
        if (typeof window.Intercom !== 'undefined' && process.env.NODE_ENV == 'production') {
            evt.preventDefault();
            window.Intercom('show');
            window.Intercom('showSpace', 'help');
        }
        if (handleClickAnalytics) handleClickAnalytics('help');
    }, []);
};

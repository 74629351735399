import { FC } from 'react';
import { Dialog } from '@headlessui/react';
import { useRouter } from 'next/navigation';
import Image from 'next/image';

type ModalCancelledPaymentProps = {
    closeModal: () => void;
    isOpen?: boolean;
    plan?: string;
};

export const ModalCancelledPayment: FC<ModalCancelledPaymentProps> = ({ isOpen, closeModal, plan }) => {
    const router = useRouter();
    const handlePaymentClick = () => {
        router.push('/pricing');
        closeModal();
    };

    return (
        <Dialog
            as="div"
            className="fixed left-0 right-0 bottom-20 z-10 overflow-y-auto"
            onClose={closeModal}
            open={!!isOpen}
        >
            <div className="min-h-screen px-4 text-center" style={{ minHeight: '100dvh !important' }}>
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-75" />

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="inline-block h-screen align-middle" aria-hidden="true">
                    &#8203;
                </span>

                <div className="inline-block w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-newLightBackground shadow-xl rounded-xl">
                    <Dialog.Title as="h3" className="text-xl leading-6 mb-4 border-b pb-4">
                        <div className="flex justify-between items-center">
                            Are you sure?
                            <button onClick={closeModal} className="flex">
                                <Image
                                    src="/close.svg"
                                    alt="close"
                                    width="20"
                                    height="20"
                                    style={{
                                        maxWidth: '100%',
                                        height: 'auto'
                                    }}
                                />
                            </button>
                        </div>
                    </Dialog.Title>

                    <div className="flex flex-col font-medium">
                        {!plan?.includes('setup-subscription') && (
                            <p className="mb-4">
                                Track Titan users get faster with the{' '}
                                {plan?.includes('premium-paid') ? 'Premium ' : 'Plus '}
                                Membership. Did you intend to cancel?
                            </p>
                        )}

                        {plan?.includes('setup-subscription') && (
                            <p className="mb-4">Track Titan users get faster using setups. Did you intend to cancel?</p>
                        )}

                        <div className="flex justify-end">
                            <button className="mr-4 p-2" type="button" onClick={closeModal}>
                                Cancel
                            </button>

                            <button
                                type="button"
                                className="text-black bg-newOrange px-4 py-2 rounded"
                                onClick={handlePaymentClick}
                            >
                                Back to payment
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

import { useQuery } from 'react-query';
import { evidentlyCheck } from './evidentlyCheck';
import { useEffect } from 'react';
import { UserType } from 'src/types';

export const useEvidentlyCheck = (
    setResult: (result: boolean) => void,
    experimentName: string,
    user?: UserType,
    defaultValue?: boolean
) => {
    const query = useQuery(['evidentlyCheck', { experimentName, user, defaultValue }], () =>
        evidentlyCheck(experimentName, user?.uuid, user?.email, defaultValue)
    );

    useEffect(() => {
        setResult(query.data ?? false);
    }, [query?.data]);

    return query;
};

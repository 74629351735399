import { usePathname } from 'next/navigation';
import { usePrevious } from './usePrevious';
import { useEffect } from 'react';
import { useTrackTitanStore } from 'src/zustand/store';

export const useResetAnalysisState = () => {
    const pathname = usePathname();
    const isAnalysisPath = pathname && (pathname.includes('/segment/') || pathname.includes('/sessions/'));
    const previousIsAnalysisPath = usePrevious(isAnalysisPath);
    const { dispatch, setGameId } = useTrackTitanStore((state) => state.analysis);

    useEffect(() => {
        if (isAnalysisPath !== previousIsAnalysisPath && !isAnalysisPath) {
            dispatch({ type: 'RESET' });
            setGameId(undefined);
        }
    }, [previousIsAnalysisPath, isAnalysisPath]);
};

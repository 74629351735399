import { sideNavClickedEvent } from 'src/analytics/layout/sideNavClicked';
import { useTrackTitanStore } from 'src/zustand/store';
import UserImpersonation from 'components/UserImpersonation';
import { impersonateUserAdminUserIds } from 'src/hooks/auth/useImpersonateUser';
import { ButtonType } from 'components/Button/type';
import Button from 'components/Button';
import { isCurrentRouteInList } from 'src/utilities';
import { WarningRounded } from 'components/Icons/Icons';
import { usePathname } from 'next/navigation';
import { useTrackTitanHydratedStore } from 'src/providers/track-titan-store-provider';
import { SideBanner } from 'components/SideBanner';
import { useIsLinkActive } from 'src/utilities/isLinkActive';
import { useRaceEngineerActive } from 'src/hooks/useRaceEngineerActive';
import { sendEvent } from 'src/hooks/useSendMailChimpEvent';

import { TopLinks } from './TopLinks';
import { SimracingLinks } from './SimracingLinks';
import { RealRacingLinks } from './RealRacingLinks';
import { HelpSupportLinks } from './HelpSupportLinks';
import { UnAuthedLinks } from './UnAuthedLinks';
import styles from './index.module.css';

interface ISideNav {
    openOnboarding: () => void;
}

const isCollapsed = false;

export const SideNav = ({ openOnboarding }: ISideNav) => {
    const { user, isCheckingAuth, impersonationUserId, authUser } = useTrackTitanHydratedStore((state) => state.auth);
    const { reportIssueModalOpen } = useTrackTitanHydratedStore((state) => state.analysis);
    const { setImpersonationUserId } = useTrackTitanStore((state) => state.auth);
    const { setReportIssueModalOpen } = useTrackTitanStore((state) => state.analysis);
    const pathname = usePathname();

    const handleClickAnalytics = (route: string) => () => sideNavClickedEvent(route, user?.email, user?.uuid);

    const isLinkActive = useIsLinkActive();

    const isRaceEngineerActive = useRaceEngineerActive();
    const onReportIssueClick = async () => {
        setReportIssueModalOpen(!reportIssueModalOpen);
        await sendEvent({ id: user?.uuid, event_name: 'report_issue_clicked' });
    };

    const isOnSessionOrSegmentPage = isCurrentRouteInList(['/segment', '/session/', '/sessions/'], pathname);

    return (
        <div className={`hidden lg:flex bg-newDarkBackground ${!isCollapsed && 'w-48 xl:w-60'} flex-col flex-shrink-0`}>
            <div
                className={`sticky overflow-y-auto ${styles.SideNavContainer}`}
                style={{ top: 72, maxHeight: 'calc(100vh - 5rem)' }}
            >
                <nav className="flex flex-col gap-6">
                    <TopLinks
                        isCollapsed={isCollapsed}
                        isLinkActive={isLinkActive}
                        handleClickAnalytics={handleClickAnalytics}
                    />
                    <SimracingLinks
                        isCollapsed={isCollapsed}
                        isLinkActive={isLinkActive}
                        handleClickAnalytics={handleClickAnalytics}
                        isLoggedIn={!isCheckingAuth && !!user}
                        user={user}
                        isRaceEngineerActive={isRaceEngineerActive}
                    />
                    <RealRacingLinks
                        isCollapsed={isCollapsed}
                        isLinkActive={isLinkActive}
                        handleClickAnalytics={handleClickAnalytics}
                        isRaceEngineerActive={isRaceEngineerActive}
                    />
                    <HelpSupportLinks
                        isCollapsed={isCollapsed}
                        isLinkActive={isLinkActive}
                        handleClickAnalytics={handleClickAnalytics}
                        openOnboarding={openOnboarding}
                        isLoggedIn={!isCheckingAuth && !!user}
                        user={user}
                    />
                    {!isCheckingAuth && !user && (
                        <UnAuthedLinks
                            isCollapsed={isCollapsed}
                            isLinkActive={isLinkActive}
                            handleClickAnalytics={handleClickAnalytics}
                        />
                    )}
                </nav>

                {user && <SideBanner user={user} isSessionOrSessionPage={isOnSessionOrSegmentPage} />}

                {user && authUser && authUser.uuid && impersonateUserAdminUserIds.includes(authUser.uuid) && (
                    <UserImpersonation
                        impersonationUserId={impersonationUserId}
                        onImpersonateUser={setImpersonationUserId}
                    />
                )}

                {isOnSessionOrSegmentPage && (
                    <div className="flex my-4 justify-end flex-1 flex-col items-center">
                        <Button buttonType={ButtonType.InvertedIcon} onClick={onReportIssueClick}>
                            <WarningRounded className="-ml-1 mr-3.5" />
                            Report Issue
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { SanityNextImage } from 'components/SanityNextImage';
import { SanityImage } from 'src/types/sanity';

type Props = {
    skeletonHeight: number;
    sanityImage?: SanityImage;
};

export const SanityImageWithSkeleton: FC<Props> = ({ sanityImage, skeletonHeight }) => {
    if (!sanityImage) {
        return (
            <div style={{ height: skeletonHeight }}>
                <Skeleton height="100%" baseColor="#262829" highlightColor="#3D3D3D" />
            </div>
        );
    }

    return <SanityNextImage sanityImage={sanityImage} alt={sanityImage.altImage} />;
};

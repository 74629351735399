import { SupportedGame } from 'components/SupportedGames';
import { useGetSanity } from './useGetSanity';
import { getSupportedGamesQuery } from './getSupportedGames';

export const useGetSupportedGames = (uuid?: string) => {
    const query = useGetSanity<SupportedGame[]>({
        query: getSupportedGamesQuery
    });

    return query;
};

import React from 'react';
import { StarIcon } from '@heroicons/react/outline';
import Link from 'next/link';

interface AchievementHeaderBadgeProps {
    points: number;
}

const AchievementHeaderBadge = ({ points }: AchievementHeaderBadgeProps) => {
    return (
        <Link href="/drive-to-win/earn">
            <div className="text-lg h-full p-3 flex flex-col justify-center hover:bg-grey-15">
                <div className="">
                    <div className="flex items-center justify-center">
                        <StarIcon className="mr-2 w-[20px]" />
                        <p className="">{points}</p>
                    </div>
                    <p className="hidden md:block text-sm text-gray-400 text-center">Points</p>
                </div>
            </div>
        </Link>
    );
};

export default AchievementHeaderBadge;

import { ClientConfig } from '@sanity/client';
import axios from 'axios';
import { vulcanApiRequest } from 'src/queries/utils';

export const sanityConfig: ClientConfig = {
    projectId: '5xqo0b3r',
    dataset: process.env.NEXT_PUBLIC_SANITY_DATASET ?? 'production',
    apiVersion: '2021-06-03', // use current UTC date - see "specifying API version"!
    useCdn: true // `false` if you want to ensure fresh data
};

export const sanityQuery = async <T = any>(query: string, uuid?: string, eventName?: string): Promise<T | null> => {
    const encoded = encodeURIComponent(query as string);

    const { data } = await vulcanApiRequest<T>(`/sanity?uuid=${uuid}&query=${encoded}&eventName=${eventName}`);

    return data;
};

import { useEffect, useMemo } from 'react';
import { checkIsMobileApplication } from 'src/hooks/checkIsMobileApplication';
import { useGetUserData } from 'src/queries/user/useGetUserData';
import { gaiaApiRequest } from 'src/queries/utils';
import { useTrackTitanHydratedStore } from 'src/providers/track-titan-store-provider';

export const useHasLoggedIntoMobileApp = () => {
    const { user } = useTrackTitanHydratedStore((state) => state.auth);
    const { data: userData } = useGetUserData(user?.uuid);

    const isMobileApplication = checkIsMobileApplication();

    const updateUserData = useMemo(
        () => (userId: string) =>
            gaiaApiRequest(`/user/${userId}/data?hasLoggedIntoMobileApp=true`, {
                method: 'PATCH'
            }),
        []
    );

    useEffect(() => {
        if (isMobileApplication && user && user.uuid && userData?.hasLoggedIntoMobileApp === false) {
            updateUserData(user.uuid);
        }
    }, [isMobileApplication, user, userData]);
};

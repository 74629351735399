import Plan from 'components/Plan';
import ProfileCardImage from 'components/ProfileCardImage';
import Link from 'next/link';
import React, { ForwardedRef, forwardRef, useState } from 'react';
import { StarIcon } from '@heroicons/react/outline';

interface IProfileCard {
    userId?: string;
    username: string;
    isPlusPlan: boolean;
    isPremiumPlan: boolean;
    isUltraPlan: boolean;
    profilePicTimestamp: number;
    onClick?: () => void;
    className?: string;
    style?: React.CSSProperties;
    points?: number;
}

const ProfileCard = forwardRef((props: IProfileCard, ref?: ForwardedRef<HTMLDivElement>) => {
    const {
        userId,
        username,
        isPlusPlan,
        isPremiumPlan,
        isUltraPlan,
        points = 0,
        profilePicTimestamp,
        onClick,
        className = '',
        style = {}
    } = props;

    const [hasProfilePic, setHasProfilePic] = useState(true);

    const mainComponent = (
        <div ref={ref} className="flex items-center">
            <div className="mr-5 text-right flex flex-col">
                <p className="text-lg ml-0.5">{username}</p>
            </div>
            <ProfileCardImage
                username={username}
                userId={userId}
                className="h-10 w-10 text-lg border-2"
                dimension={40}
                size="small"
                timestamp={profilePicTimestamp}
                hasProfilePic={hasProfilePic}
                setHasProfilePic={setHasProfilePic}
            />
        </div>
    );

    const containerClass =
        'flex items-center hover:bg-grey-11 transition-all duration-500 py-3 px-5 rounded-md ' + className;

    if (onClick) {
        return (
            <button className={containerClass} onClick={onClick} type="button" style={style}>
                {mainComponent}
            </button>
        );
    }

    return (
        <Link href={`/profile/${userId}`} className={containerClass} style={style}>
            {mainComponent}
        </Link>
    );
});

ProfileCard.displayName = 'ProfileCard';

export default ProfileCard;

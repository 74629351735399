import { FC } from 'react';
import Image from 'next/image';

import { SupportedGame, SupportedGames } from 'components/SupportedGames';
import Button from 'components/Button';

type Props = {
    goNextSlide: () => void;
    handleDownloadClick: () => void;
    setShowDownloadSlide2: (boolean: boolean) => void;
    supportedGames?: SupportedGame[];
};

export const OnboardingIntro: FC<Props> = ({
    goNextSlide,
    handleDownloadClick,
    setShowDownloadSlide2,
    supportedGames
}) => {
    const handleDontKnowClick = () => {
        goNextSlide();
        setShowDownloadSlide2(true);
    };

    return (
        <div className="mt-4 px-24 pb-6">
            <p className="mb-4">
                To connect Track Titan to your games on either PC or console, our logger needs to be installed on a
                computer.
            </p>

            <Button onClick={handleDownloadClick} className="w-full text-xl">
                Download
                <div className="flex relative ml-2" style={{ top: -2 }}>
                    <Image
                        src="/download.svg"
                        alt="download"
                        width="32"
                        height="32"
                        style={{
                            maxWidth: '100%',
                            height: 'auto'
                        }}
                    />
                </div>
            </Button>

            <div className="mt-2 flex justify-between">
                <button className="underline" onClick={goNextSlide}>
                    Already downloaded?
                </button>

                <button
                    type="button"
                    className="underline flex justify-between items-center"
                    onClick={handleDontKnowClick}
                >
                    What is the Data Logger?
                </button>
            </div>

            <h2 className="mt-12 mb-4 text-2xl font-semibold">Supported Games:</h2>

            <SupportedGames supportedGames={supportedGames} />
        </div>
    );
};

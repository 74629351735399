import Button from 'components/Button';
import { ButtonType } from 'components/Button/type';
import { Checked } from 'components/Icons';
import { SupportedGame, SupportedGames } from 'components/SupportedGames';

interface DesktopLoggerProps {
    supportedGames: SupportedGame[];
    onEmailClick: () => void;
    onboardingEmailHasBeenSent: boolean;
    emailLoading?: boolean;
}

export const DesktopLogger = ({
    supportedGames,
    onEmailClick,
    onboardingEmailHasBeenSent,
    emailLoading
}: DesktopLoggerProps) => (
    <div className="flex flex-col items-center">
        <h2 className="text-2xl mb-1 text-center">{`Awesome, one more step`}</h2>
        <p className="text-base my-1 mx-4 text-center">
            We need you to jump onto Track Titan <span className="text-orange-500">ON YOUR COMPUTER</span> and download
            the data logger to finish your setup
        </p>

        <div className="p-1.5 px-4 bg-grey-05 w-full rounded-md my-1.5">
            <h3 className="text-lg mt-1 mb-2">Supported Games:</h3>

            <SupportedGames supportedGames={supportedGames} mobileOnboarding />
        </div>

        <p className="text-base my-1 mx-4 text-center">
            We can email you everything you need to get started on your computer
        </p>

        <Button
            onClick={onEmailClick}
            buttonType={onboardingEmailHasBeenSent ? ButtonType.Green : ButtonType.Blue}
            className="w-full"
            disabled={emailLoading}
        >
            {onboardingEmailHasBeenSent ? 'Email sent!' : 'Email me everything I need'}

            {onboardingEmailHasBeenSent && <Checked className="ml-2 w-5 h-5 text-white" />}
        </Button>
    </div>
);

import { SanityNextImage } from 'components/SanityNextImage';
import { SupportedGame } from 'components/SupportedGames';
import Image from 'next/image';
import { getAppStoreLinks } from 'src/utilities/getAppStoreLinks';

interface AppDownloadProps {
    activeGame: SupportedGame;
}

export const AppDownload = ({ activeGame }: AppDownloadProps) => {
    const { android: androidStoreUrl, ios: iosStoreUrl } = getAppStoreLinks();

    return (
        <div className="flex flex-col items-center">
            <h2 className="text-2xl mb-4 text-center">{`Install the App`}</h2>
            <div className="w-full flex justify-center my-4">
                <div className="w-48 h-48 overflow-hidden border-orange-600 border-4 rounded-full relative">
                    <SanityNextImage sanityImage={activeGame?.image} fill />
                </div>
            </div>
            <p className="text-lg my-2 mx-4 text-center">
                To make sure we can get your data, please download the mobile app
            </p>

            <a target="_blank" rel="noopener noreferrer" href={androidStoreUrl}>
                <Image src="/play-store.png" alt="App Store" width={228} height={50} className="mt-1" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={iosStoreUrl}>
                <Image src="/app-store.svg" alt="App Store" width={200} height={50} className="mt-2" />
            </a>
        </div>
    );
};

import { useEffect } from 'react';
import { useTrackTitanStore } from 'src/zustand/store';
import Bowser from 'bowser';
import { useTrackTitanHydratedStore } from 'src/providers/track-titan-store-provider';

export const useUserAgent = () => {
    const { setUserAgent } = useTrackTitanStore((state) => state.app);
    const { userAgent } = useTrackTitanHydratedStore((state) => state.app);

    useEffect(() => {
        if (!userAgent) {
            const parser = Bowser.getParser(window.navigator.userAgent);
            setUserAgent(parser.getPlatform());
        }
    }, [userAgent, setUserAgent]);
};

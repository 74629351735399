'use client';
import LoadingBar from 'components/LoadingBar';
import { useEffect, useState } from 'react';
import Image from 'next/image';
import styles from './index.module.css';

interface ILoadingAdvice {
    message: string;
    isClientPage?: boolean;
    showMessage?: boolean;
}

const LoadingAdvice = ({ message, isClientPage, showMessage }: ILoadingAdvice) => {
    const [isOnTop, setIsOnTop] = useState(false);
    const [removeBackground, setRemoveBackground] = useState<'none' | 'top' | 'full'>('none');
    const [clientShouldStart, setClientShouldStart] = useState(false);

    const isServer = typeof window === 'undefined';

    useEffect(() => {
        if (!showMessage) {
            setIsOnTop(false);
            setRemoveBackground('none');
            setClientShouldStart(false);
        }
    }, [showMessage]);

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (!isServer && !clientShouldStart) {
            interval = setInterval(() => {
                const loadingAdvices = document.querySelectorAll(`#loading-advice`);

                if (loadingAdvices.length === 1) {
                    setClientShouldStart(true);
                }
            }, 500);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isServer, clientShouldStart]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        let timeout2: NodeJS.Timeout;

        if (isClientPage && clientShouldStart) {
            setIsOnTop(true);

            timeout = setTimeout(() => {
                setRemoveBackground('top');
            }, 2000);

            timeout2 = setTimeout(() => {
                setRemoveBackground('full');
            }, 6000);
        }

        return () => {
            clearTimeout(timeout);
            clearTimeout(timeout2);
        };
    }, [isClientPage, clientShouldStart]);

    if (!showMessage || (!isServer && !isClientPage)) {
        return null;
    }

    return (
        <div
            className={`fixed overflow-hidden top-0 right-0 left-0 ${
                removeBackground === 'full' ? 'h-0' : removeBackground === 'top' ? 'h-20' : 'h-full'
            }`}
            style={{ zIndex: 10000000000000 }}
            id="loading-advice"
        >
            <div
                className={`bg-black  absolute inset-0 ${
                    isOnTop ? 'opacity-0' : 'opacity-50'
                } transition-all duration-1000`}
            />

            <div className={`${styles.LoadingContainer} ${isOnTop ? styles.LoadingContainerOnTop : ''}`}>
                <div className={isOnTop ? styles.LoadingContainerOnTopInnerContainer : ''}>
                    <div className="relative overflow-hidden rounded-lg bg-grey-15 px-8 p-6">
                        <div className={`flex w-full justify-center ${isOnTop ? 'h-0 mb-0' : 'h-auto mb-6'}`}>
                            <Image src="/wordmark.png" alt="track titan" width="220" height="22" />
                        </div>

                        <div
                            className={`flex w-full items-center justify-center overflow-hidden ${
                                isOnTop ? 'h-0 mb-0' : 'h-auto mb-4'
                            }`}
                        >
                            <LoadingBar />
                        </div>

                        <p className="text-center text-lg">{message}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingAdvice;

import { ReactNode, useEffect, useMemo, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { useMediaQuery } from 'react-responsive';
import { hotjar } from 'react-hotjar';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat'; // load on demand
import relativeTime from 'dayjs/plugin/relativeTime'; // load on demand
import customParseFormat from 'dayjs/plugin/customParseFormat'; // load on demand
import toast from 'react-hot-toast';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';

import { ModalOnboarding } from 'components/ModalOnboarding';
import { useTrackTitanStore } from 'src/zustand/store';
import { mediaQuery } from 'src/tokens';
import { isCurrentRouteInList } from 'src/utilities';
import { useAuth } from 'src/hooks/auth/useAuth';
import { IMiscImage } from 'src/queries/sanity/getMiscImages';
import { Header } from 'components/Header';
import { SideNav } from 'components/SideNav';
import { ModalCancelledPayment } from 'components/ModalCancelledPayment';
import { Footer } from 'components/Footer';
import { useIntercomLoaded } from 'src/hooks/useIntercomLoaded';
import { useResetBodyOverflow } from 'src/hooks/useResetBodyOverflow';
import { useUserAgent } from 'src/hooks/useUserAgent';
import { useUtmData } from 'src/hooks/useUtmData';
import { checkIsMobileApplication } from 'src/hooks/checkIsMobileApplication';
import { useGetUserData } from 'src/queries/user/useGetUserData';
import { useRemoveOldLocalStorage } from 'src/hooks/useRemoveOldLocalStorage';
import { useBreadcrumbs } from 'src/hooks/useBreadcrumbs';
import * as ga from 'src/analytics/utils/google';
import { useOnNavigationChange } from 'src/hooks/useOnNavigationChange';
import MobileOnboarding from 'components/MobileOnboarding';
import { SupportedGame } from 'components/SupportedGames';
import { useResetAnalysisState } from 'src/hooks/useResetAnalysisState';
import { useHasLoggedIntoMobileApp } from 'src/hooks/useHasLoggedIntoMobileApp';
import { useTrackTitanHydratedStore } from 'src/providers/track-titan-store-provider';
import LoadingAdvice from 'components/LoadingAdvice';
import { useSendClientId } from 'src/hooks/useSendClientId';
import { useTracking } from 'src/analytics/utils/useTracking';
import { useEventDrivenRouter } from 'src/hooks/useEventDrivenRouter';
import { useGetSanity } from 'src/queries/sanity/useGetSanity';
import InfoModal from 'components/InfoModal';
import { useRegisterId } from 'src/hooks/useRegisterId';
import ModalManager from 'components/ModalManager';
import { sendEvent as sendMailchimpEvent } from 'src/hooks/useSendMailChimpEvent';
import { useGetSessions } from 'src/queries/session/useGetSessions';
import { useIntercomPosition } from 'src/hooks/useIntercomPosition';
import { useRedirectIncompleteUser } from 'src/hooks/useRedirectIncompleteUser';
import { useGetUserDetails } from 'src/queries/user/useGetUserDetails';

import { redirectRoute } from './utils';
import styles from './index.module.css';

dayjs.extend(advancedFormat); // use plugin
dayjs.extend(relativeTime); // use plugin
dayjs.extend(customParseFormat); // use plugin

// Import dayjs locales
import 'dayjs/locale/de';
import 'dayjs/locale/id';
import 'dayjs/locale/pl';
import 'dayjs/locale/it';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/pt';
import 'dayjs/locale/ja';
import 'dayjs/locale/hi';
import 'dayjs/locale/tr';
import { UtmData } from 'src/zustand/app';
import { useGetUserAchievements } from 'src/queries/achievements/getAchievements';
import { useGetUserPoints } from 'src/queries/user/useGetUserPoints';

interface IPageLayout {
    children: ReactNode;
    supportedGames?: SupportedGame[];
    miscImages?: IMiscImage[];
    loadingAdvice?: string;
    cancelPlanParam?: string;
    showOnboardingModal?: string;
    payment_success?: string;
    gameSelectionGameId?: string;
    utmData: UtmData;
}

export const PageLayout = ({
    children,
    supportedGames,
    miscImages,
    loadingAdvice,
    cancelPlanParam,
    showOnboardingModal,
    payment_success,
    gameSelectionGameId,
    utmData
}: IPageLayout) => {
    const [isCancelledPaymentModalOpen, setIsCancelledPaymentModalOpen] = useState<boolean>();
    const [trackingEventSent, setTrackingEventSent] = useState(false);
    const router = useRouter();
    const pathname = usePathname();
    const { user, isCheckingAuth, isOnboardingModalOpen } = useTrackTitanHydratedStore((state) => state.auth);
    const { setIsOnboardingModalOpen } = useTrackTitanStore((state) => state.auth);
    const { dispatch: dispatchContentRevealed } = useTrackTitanStore((state) => state.contentRevealed);
    const [emailLoading, setEmailLoading] = useState(false);
    const [onboardingModalOverride, setOnboardingModalOverride] = useState(false);
    const [userDataTimestamp] = useState(Date.now());
    const [onboardingEmailHasBeenSent, setOnboardingEmailHasBeenSent] = useState(false);
    const { savedRoute, slide, ghostEventDownloaded, completedCountdown, sanityInfoModalsOpened } =
        useTrackTitanHydratedStore((state) => state.storage);
    const { setSavedRoute, setSlide, setGhostEventDownloaded, setCompletedCountdown, addSanityInfoModalOpened } =
        useTrackTitanStore((state) => state.storage);
    const { rightSidebarWidth, setupReferral } = useTrackTitanHydratedStore((state) => state.app);
    const { setRightSidebarWidth, setSetupReferral } = useTrackTitanStore((state) => state.app);

    const { data: infoModalData } = useGetSanity<{ _id: string; title: string; content: any[] }[]>({
        query: "*[_type == 'infoModal']{_id, title, content}"
    });

    const sanityInfoModal = useMemo(() => {
        if (infoModalData && sanityInfoModalsOpened) {
            return infoModalData.find((modal) => !sanityInfoModalsOpened[modal._id]);
        }
    }, [sanityInfoModalsOpened, infoModalData]);

    const isTabletOrLarger = useMediaQuery({ query: mediaQuery.tablet });
    const suppressDueToNews = isCurrentRouteInList(['/news'], pathname);

    useRegisterId();

    const { data: sessionData } = useGetSessions(user?.uuid, 1);
    const hasDroveASession = !sessionData || (sessionData.data && sessionData?.data?.sessions?.length > 0);
    const { data: userPoints } = useGetUserPoints(user?.uuid);

    const overallPoints = userPoints?.data?.points ?? 0;

    const gameSelectionGame = useMemo(
        () =>
            supportedGames &&
            supportedGames.find((game) => game.gameId.toLowerCase() === gameSelectionGameId?.toLowerCase()),
        [supportedGames, gameSelectionGameId]
    );

    const onboardingMiscImage = useMemo(
        () => miscImages && miscImages.find((image) => image.name === 'onboarding-slide2'),
        [miscImages]
    );

    const { data: userData } = useGetUserData(user?.uuid, userDataTimestamp);
    const { data: userDetails } = useGetUserDetails(user?.uuid);

    const track = useTracking();

    const isMobileApplication = checkIsMobileApplication();
    const isDesktopView = useMediaQuery({ query: mediaQuery.tablet });
    const shouldHideFooter =
        isMobileApplication || isCurrentRouteInList(['/segment', '/session/', '/sessions/', '/news'], pathname);
    const showLoadingAdvice = isCurrentRouteInList(['/segment', '/session/'], pathname);
    const shouldHideAllModals = isCurrentRouteInList(
        ['/cancel', '/oauth/link', '/complete-registration', '/oauth/callback'],
        pathname
    );
    const showNoLayout = isCurrentRouteInList(['/racing-line', '/oauth/link', '/oauth/callback'], pathname);
    const showOldOnboardingModal =
        userDetails?.has_completed_activation === true || !isCurrentRouteInList(['/dashboard', '/sessions/'], pathname);

    useAuth();
    useUserAgent();
    useUtmData(utmData);
    useResetBodyOverflow();
    useIntercomLoaded();
    useResetAnalysisState();
    useHasLoggedIntoMobileApp();
    useSendClientId();
    useEventDrivenRouter();
    useRedirectIncompleteUser();
    useIntercomPosition(pathname);

    useEffect(() => {
        if (showOnboardingModal === 'true') {
            setIsOnboardingModalOpen(true);
        }
    }, [showOnboardingModal]);

    useEffect(() => {
        if (slide !== undefined && slide >= 1 && userData?.hasLoggedInOnGhost === false && !hasDroveASession) {
            setIsOnboardingModalOpen(true);
        }
    }, [setIsOnboardingModalOpen, slide, userData?.hasLoggedInOnGhost, hasDroveASession]);

    useEffect(() => {
        if (payment_success === 'false' && isCancelledPaymentModalOpen === undefined) {
            setIsCancelledPaymentModalOpen(true);
        }
    }, [isCancelledPaymentModalOpen, payment_success]);

    useEffect(() => {
        if (payment_success === 'true' && trackingEventSent === false) {
            const uuid = user?.uuid;
            track({ trackEvent: 'Subscribe' }, { uuid });

            setTrackingEventSent(true);
        }
    }, [payment_success, track, trackingEventSent, user?.uuid]);

    useEffect(() => {
        if (!isCheckingAuth && !isMobileApplication && pathname) {
            const redirectTo = redirectRoute({
                user,
                pathname,
                setSavedRoute,
                savedRoute,
                setupReferral,
                setSetupReferral
            });

            if (redirectTo !== null) {
                router.push(redirectTo);
            }
        }
    }, [isCheckingAuth, pathname, savedRoute, setSavedRoute, user]);

    useEffect(() => {
        if (pathname !== '/academy' && rightSidebarWidth !== 0) {
            setRightSidebarWidth(0);
        }
    }, [pathname]);

    useEffect(() => {
        dispatchContentRevealed({ type: 'RESET' });
    }, [pathname]);

    useRemoveOldLocalStorage();
    useBreadcrumbs();

    useEffect(() => {
        const hotjarId = 2788265;
        const hotjarSFV = 6;

        hotjar.initialize(hotjarId, hotjarSFV);
    }, []);

    useOnNavigationChange((url) => {
        ga.pageview(url);
        track({ trackEvent: 'PageView' }, { url });
    });

    const onEmailClick = async () => {
        setEmailLoading(true);
        if (user?.email && onboardingEmailHasBeenSent === false) {
            const response = await sendMailchimpEvent({ id: user?.uuid, event_name: 'onboarding_instructions' });

            if (response.success) {
                setOnboardingEmailHasBeenSent(true);

                setTimeout(() => {
                    setIsOnboardingModalOpen(false);
                    setOnboardingEmailHasBeenSent(false);
                }, 4000);
            }
            if (!response.success) toast.error('Something went wrong, please try again later.');
        }
        setEmailLoading(false);
    };

    if (showNoLayout) {
        return <div className="w-screen h-screen">{children}</div>;
    }

    return (
        <div
            className="transition-all duration-500 relative"
            style={{ marginRight: `${isTabletOrLarger ? rightSidebarWidth : 0}rem` }}
        >
            <div className={styles.PageOuterContainer}>
                {!isMobileApplication && (
                    <Header
                        rightSidebarWidth={rightSidebarWidth}
                        openOnboarding={() => {
                            setOnboardingModalOverride(true);
                            setIsOnboardingModalOpen(true);
                        }}
                        points={overallPoints}
                    />
                )}

                <div className={`${styles.PageContainer} ${isMobileApplication ? styles.PageContainerMobileApp : ''}`}>
                    {!isMobileApplication && (
                        <SideNav
                            openOnboarding={() => {
                                setOnboardingModalOverride(true);
                                setIsOnboardingModalOpen(true);
                            }}
                        />
                    )}

                    <main className="flex-grow w-full bg-grey-00">{children}</main>
                </div>

                <div className={`${shouldHideFooter ? 'hidden' : ''}`}>
                    <Footer />
                </div>
            </div>
            {!suppressDueToNews && loadingAdvice && (
                <span className="hidden md:inline">
                    <LoadingAdvice message={loadingAdvice} isClientPage showMessage={showLoadingAdvice} />{' '}
                </span>
            )}

            {!shouldHideAllModals && (
                <ModalManager
                    modals={[
                        {
                            open:
                                !isMobileApplication &&
                                !!isOnboardingModalOpen &&
                                isDesktopView &&
                                ((!suppressDueToNews && showOldOnboardingModal) || onboardingModalOverride),
                            component: (
                                <ModalOnboarding
                                    user={user}
                                    isOnboardingModalOpen={isOnboardingModalOpen}
                                    setIsOnboardingModalOpen={setIsOnboardingModalOpen}
                                    slide={slide}
                                    setSlide={setSlide}
                                    ghostEventDownloaded={ghostEventDownloaded}
                                    setGhostEventDownloaded={setGhostEventDownloaded}
                                    completedCountdown={completedCountdown}
                                    setCompletedCountdown={setCompletedCountdown}
                                    supportedGames={supportedGames}
                                    onboardingMiscImage={onboardingMiscImage?.image}
                                />
                            )
                        },
                        {
                            open:
                                !isMobileApplication && !!isOnboardingModalOpen && !suppressDueToNews && !isDesktopView,
                            component: (
                                <MobileOnboarding
                                    supportedGames={supportedGames ?? []}
                                    onEmailClick={onEmailClick}
                                    buttonClassName="fixed bottom-4 right-4 z-50 block md:hidden"
                                    onboardingEmailHasBeenSent={onboardingEmailHasBeenSent}
                                    emailLoading={emailLoading}
                                    open={isOnboardingModalOpen}
                                    setOpen={setIsOnboardingModalOpen}
                                    userId={user?.uuid!}
                                    gameSelectionGame={gameSelectionGame}
                                    email={user?.email}
                                />
                            )
                        },
                        {
                            open: !!sanityInfoModal,
                            component: <InfoModal {...sanityInfoModal!} onClose={addSanityInfoModalOpened} />
                        },
                        {
                            open: !isMobileApplication && !!isCancelledPaymentModalOpen,
                            component: (
                                <ModalCancelledPayment
                                    isOpen={!!isCancelledPaymentModalOpen}
                                    closeModal={() => setIsCancelledPaymentModalOpen(false)}
                                    plan={cancelPlanParam}
                                />
                            )
                        }
                    ]}
                />
            )}
        </div>
    );
};

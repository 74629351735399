import Image from 'next/image';
import toast from 'react-hot-toast';
import { AcademicCapIcon } from '@heroicons/react/outline';

import SideNavGroup from 'components/SideNavGroup';
import SideNavItem from 'components/SideNavItem';
import { useTrackTitanStore } from 'src/zustand/store';
import { useTrackTitanHydratedStore } from 'src/providers/track-titan-store-provider';
import { useGetPremiumPlanLink } from 'src/queries/user/useGetPremiumPlanLink';
import { Adjustments } from 'components/Icons/Adjustments';
import { Crown } from 'components/Icons/Crown';
import { Group } from 'components/Icons/Group';
import { useLeaderboardLink } from 'src/hooks/useLeaderboardLink';
import { UserType } from 'src/types';
import { StarIcon } from '@heroicons/react/outline';
import { ActiveDot } from './ActiveDot';
import { SteeringWheel } from 'components/Icons/Icons';

interface ISimracingLinks {
    handleClickAnalytics: (route: string) => () => void;
    isLinkActive: (route?: string) => boolean;
    isCollapsed?: boolean;
    isLoggedIn: boolean;
    user?: UserType;
    isRaceEngineerActive?: boolean;
}

export const SimracingLinks = ({
    handleClickAnalytics,
    isLinkActive,
    isCollapsed = false,
    isLoggedIn,
    user,
    isRaceEngineerActive
}: ISimracingLinks) => {
    const { hasClickedPremiumPlanLink } = useTrackTitanHydratedStore((state) => state.storage);
    const { setHasClickedPremiumPlanLink } = useTrackTitanStore((state) => state.storage);

    const { leaderboardLink } = useLeaderboardLink();
    const { data: hasPremiumPlanData } = useGetPremiumPlanLink(!!user?.isPremiumPlan, user?.uuid);

    const onPremiumPlanClick = () => {
        if (!hasPremiumPlanData || !hasPremiumPlanData.premiumPlanLink) {
            toast('We are currently setting up your training plan, the link will be available here shortly', {
                icon: 'ℹ️'
            });
        } else {
            setHasClickedPremiumPlanLink(true);
        }

        handleClickAnalytics('Premium');
    };

    return (
        <SideNavGroup name="Simracing Insights">
            <SideNavItem
                link="/sessions"
                onClick={handleClickAnalytics('sessions')}
                isLinkActive={isLinkActive}
                linkActiveOverride={isRaceEngineerActive === undefined ? undefined : !isRaceEngineerActive}
            >
                <Image
                    src="/sessions-icon.svg"
                    alt="sessions"
                    width="24"
                    height="24"
                    style={{
                        maxWidth: '100%',
                        height: 'auto'
                    }}
                />
                {!isCollapsed && 'Your Sessions'}
            </SideNavItem>

            <SideNavItem
                link="/drive-to-win"
                onClick={handleClickAnalytics('drivetowin')}
                isLinkActive={() => isLinkActive('/drive-to-win')}
            >
                <StarIcon width={24} height={24} />
                {!isCollapsed && 'Drive to Win'}
            </SideNavItem>

            <SideNavItem
                link={leaderboardLink}
                onClick={handleClickAnalytics('leaderboards')}
                isLinkActive={() => isLinkActive('/leaderboards')}
            >
                <Image
                    src="/leaderboard-icon.svg"
                    alt="dashboard"
                    width="24"
                    height="24"
                    style={{
                        maxWidth: '100%',
                        height: 'auto'
                    }}
                />
                {!isCollapsed && 'Leaderboards'}
            </SideNavItem>

            <SideNavItem link="/setups" onClick={handleClickAnalytics('setups')} isLinkActive={isLinkActive}>
                <Adjustments className="" />
                {!isCollapsed && 'Esports Setups'}
            </SideNavItem>

            <SideNavItem link="/academy" onClick={handleClickAnalytics('academy')} isLinkActive={isLinkActive}>
                <AcademicCapIcon className="w-6 h-6" />
                {!isCollapsed && 'Academy'}
            </SideNavItem>

            {isLoggedIn && (
                <SideNavItem link="/pods" onClick={handleClickAnalytics('pods')} isLinkActive={isLinkActive}>
                    <Group className="w-6 h-6" />
                    <span className="tracking-tight">{!isCollapsed && 'Community Pods'}</span>
                </SideNavItem>
            )}

            {user?.isPremiumPlan && (
                <SideNavItem
                    link={
                        hasPremiumPlanData && hasPremiumPlanData.premiumPlanLink
                            ? hasPremiumPlanData.premiumPlanLink
                            : undefined
                    }
                    onClick={onPremiumPlanClick}
                    isLinkActive={isLinkActive}
                    externalLink
                >
                    <Crown className="w-6 h-6" />
                    {!isCollapsed && 'Premium'}
                    {!hasClickedPremiumPlanLink && hasPremiumPlanData && hasPremiumPlanData.premiumPlanLink && (
                        <ActiveDot />
                    )}
                </SideNavItem>
            )}

            <SideNavItem
                link="https://www.tracktitan.io/recommended-hardware"
                onClick={handleClickAnalytics('hardware')}
                isLinkActive={isLinkActive}
                externalLink
            >
                <SteeringWheel className="w-6 h-6 min-w-6" />
                <span className="whitespace-pre xl:inline hidden" style={{ letterSpacing: '-0.25px' }}>
                    {!isCollapsed && 'Recommended Hardware'}
                </span>
                <span className="xl:hidden inline">{!isCollapsed && 'Hardware'}</span>
            </SideNavItem>
        </SideNavGroup>
    );
};

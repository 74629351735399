import { useEffect, useState } from 'react';

export const useCountdown = (seconds: number) => {
    const [secondsRemaining, setSecondsRemaining] = useState(seconds);

    useEffect(() => {
        const interval = setInterval(() => {
            setSecondsRemaining(secondsRemaining - 1);
        }, 1000);

        if (secondsRemaining <= 0) clearInterval(interval);

        return () => clearInterval(interval);
    }, [secondsRemaining]);

    return { secondsRemaining };
};

import { sendAnalyticsEvent } from '../utils';

// Fired when the button on the community banner in the side nav is clicked, this shows when the user is on the community plan

export const sideCommunityBannerClickedEvent = (community: boolean, email?: string, uuid?: string) =>
    sendAnalyticsEvent({
        uuid,
        email,
        eventName: community ? 'dashboard_community_banner_clicked' : 'dashboard_plus_to_ultra_banner_clicked'
    });

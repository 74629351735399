import React, { useMemo, useState } from 'react';
import ProfileCard from 'components/ProfileCard';
import SideNavItem from 'components/SideNavItem';
import Image from 'next/image';
import { sideNavClickedEvent } from 'src/analytics/layout/sideNavClicked';
import { Logout } from 'components/Icons/Logout';
import { User } from 'components/Icons/User';
import { useMeasure } from 'react-use';
import { useSignOut } from 'src/hooks/useSignOut';
import { useIsLinkActive } from 'src/utilities/isLinkActive';
import Plan from 'components/Plan';
import { VulcanResponse } from 'src/queries/types';
import { LapUsageResponse } from 'src/queries/user/useGetLapUsage';
import Link from 'next/link';
import { checkIsMobileApplication } from 'src/hooks/checkIsMobileApplication';

interface IProfileHeaderCard {
    userId?: string;
    username: string;
    isPlusPlan: boolean;
    isPremiumPlan: boolean;
    isUltraPlan: boolean;
    isSetupSubscriptionPlan: boolean;
    profilePicTimestamp: number;
    email?: string;
    lapUsageData?: VulcanResponse<LapUsageResponse>;
    points?: number;
}

const ProfileHeaderCard = ({
    userId,
    username,
    email,
    isPlusPlan,
    isPremiumPlan,
    isUltraPlan,
    profilePicTimestamp,
    lapUsageData,
    isSetupSubscriptionPlan,
    points = 0
}: IProfileHeaderCard) => {
    const [open, setOpen] = useState(false);
    const [profileCardRef, { width }] = useMeasure<HTMLDivElement>();
    const isCommunityPlan = !isUltraPlan && !isPremiumPlan && !isPlusPlan;
    const onProfileClick = () => {
        setOpen(!open);
    };

    const handleClickAnalytics = (route: string) => () => sideNavClickedEvent(route, email, userId);

    const isMobileApplication = checkIsMobileApplication();

    const isLinkActive = useIsLinkActive();

    const sideNavItemClass = 'bg-grey-11 hover:bg-grey-15 px-4';

    const handleSignOut = useSignOut();

    const widthInRemsWithPadding = width / 16 + 2;

    const [lapsUsed, lapLimit] = useMemo(() => {
        return [lapUsageData?.data?.lapsRecorded ?? 0, lapUsageData?.data?.lapUsageLimit ?? 50];
    }, [lapUsageData]);

    const allLapsUsed = lapsUsed >= lapLimit;

    return (
        <div
            className={`flex h-14 md:h-18 items-center justify-end relative transition-all duration-500`}
            style={{ width: open ? `${Math.max(widthInRemsWithPadding, 14)}rem` : `${widthInRemsWithPadding}rem` }}
        >
            <ProfileCard
                userId={userId}
                username={username}
                isPlusPlan={isPlusPlan}
                isPremiumPlan={isPremiumPlan}
                isUltraPlan={isUltraPlan}
                profilePicTimestamp={profilePicTimestamp}
                onClick={onProfileClick}
                className={`rounded-t-none rounded-b-none h-14 md:h-18 py-0 flex justify-center w-full whitespace-pre ${
                    open ? 'bg-grey-11' : ''
                }`}
                ref={profileCardRef}
                points={points}
            />

            {open && (
                <div className="absolute bg-grey-11 pb-2 pt-2 md:pt-0 right-0 left-0 rounded-md rounded-t-none shadow-lg top-12 md:top-18">
                    <div className="bg-newHeaderBackground py-2 px-4 flex flex-col mb-2">
                        <Plan
                            isPlusPlan={isPlusPlan}
                            isPremiumPlan={isPremiumPlan}
                            isUltraPlan={isUltraPlan}
                            textSmall={false}
                        />
                        <span>
                            <span className="text-gray-400">Laps: </span>
                            {isCommunityPlan ? `${lapsUsed} of ${lapLimit} used` : 'Unlimited'}
                            {allLapsUsed && (
                                <a href="/pricing">
                                    <span className="text-orange-600 ml-1 whitespace-nowrap text-sm">Get Ultra</span>
                                </a>
                            )}
                        </span>
                        <span>
                            <span className="text-gray-400">Setups:</span>{' '}
                            {(isCommunityPlan || isPlusPlan || isPremiumPlan) && !isSetupSubscriptionPlan && (
                                <>
                                    {!isMobileApplication ? (
                                        <Link href="/pricing" className="text-orange-600">
                                            Get Ultra
                                        </Link>
                                    ) : (
                                        'Visit website'
                                    )}
                                </>
                            )}
                            {isUltraPlan && 'Full access'}
                            {isSetupSubscriptionPlan && 'Setup subscription'}
                        </span>
                        <span>
                            <span className="text-gray-400">Points:</span>{' '}
                            <Link href="/drive-to-win/earn" className="hover:underline">
                                <span className="text-orange-600">{points}</span>&nbsp;{' '}
                                <span className="text-xs">(See Points)</span>
                            </Link>
                        </span>
                    </div>
                    <SideNavItem
                        link={`/profile/${userId}`}
                        onClick={() => {
                            setOpen(false);
                            handleClickAnalytics('profile');
                        }}
                        isLinkActive={isLinkActive}
                        className={sideNavItemClass}
                    >
                        <User className="h-6 w-6 scale-75" />
                        Profile
                    </SideNavItem>

                    <SideNavItem
                        link="/settings"
                        onClick={() => {
                            setOpen(false);
                            handleClickAnalytics('settings');
                        }}
                        isLinkActive={isLinkActive}
                        className={sideNavItemClass}
                    >
                        <Image
                            src="/settings-icon.svg"
                            alt="settings"
                            width="24"
                            height="24"
                            style={{
                                maxWidth: '100%',
                                height: 'auto'
                            }}
                        />
                        Settings
                    </SideNavItem>
                    <SideNavItem
                        link="/login"
                        onClick={handleSignOut}
                        isLinkActive={isLinkActive}
                        className={sideNavItemClass}
                    >
                        <Logout className="h-6 w-6 scale-75 ml-0.5" />
                        Log out
                    </SideNavItem>
                </div>
            )}
        </div>
    );
};

export default ProfileHeaderCard;

import { SupportedGame } from 'components/SupportedGames';
import { getSanity } from './getSanity';

export const getSupportedGamesQuery = `*[_type == "game" && count(supportedPlatforms) > 0] | order(gameId desc) {
    gameId,
    image,
    supportedPlatforms[]->{name},
    name,
    mobileSupported
  }`;

export const getSupportedGames = async () => getSanity<SupportedGame[]>(getSupportedGamesQuery);

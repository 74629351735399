import { FC, useState } from 'react';
import Link from 'next/link';

import { SanityNextImage } from 'components/SanityNextImage';
import { SupportedGame } from 'components/SupportedGames';
import { gameIncludesCars } from 'src/utilities/gameIncludesCars';

type Props = {
    closeModal: () => void;
    supportedGames?: SupportedGame[];
};

const shadow =
    'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5)) drop-shadow(6px 7px 4px rgba(0, 0, 0, 0.15)) drop-shadow(0px 1.22276px 1.22276px rgba(0, 0, 0, 0.25))';

export const Games: FC<Props> = ({ closeModal, supportedGames }) => {
    const [selectedGame, setSelectedGame] = useState<SupportedGame>();

    const handleGameClick = (game: SupportedGame) => () => {
        if (game.gameId === selectedGame?.gameId) {
            setSelectedGame(undefined);
        } else {
            setSelectedGame(game);
        }
    };

    if (!supportedGames) return null;

    return (
        <>
            <ul className="mt-10 pb-6 px-8 flex justify-between gap-8 flex-wrap">
                {supportedGames.map((game) => {
                    return (
                        <li key={game.gameId} className="">
                            <button
                                type="button"
                                className="flex flex-col items-center"
                                onClick={handleGameClick(game)}
                            >
                                <div
                                    style={{ filter: shadow }}
                                    className={`w-44 rounded-lg overflow-hidden ${
                                        selectedGame?.gameId === game.gameId ? 'border-orange-500 border-4' : ''
                                    } `}
                                >
                                    <SanityNextImage sanityImage={game.image} alt={game.name} />
                                </div>
                            </button>
                        </li>
                    );
                })}
            </ul>

            <div className="pb-8" style={{ minHeight: 120 }}>
                {selectedGame &&
                    ['F12020', 'F12021', 'F12022', 'F12023', 'F12024', 'forza'].includes(selectedGame.gameId) && (
                        <div className="w-full">
                            <p className="text-newOrange font-bold text-2xl mb-2 text-center">One more step</p>
                            <div className="w-full flex justify-between px-8 items-center">
                                <p className="text-xl">Change your {selectedGame.name} telemetry settings</p>

                                <Link
                                    href={`/onboarding/${selectedGame.gameId}`}
                                    className="bg-newOrange rounded p-2 font-bold text-lg w-40 text-center"
                                    onClick={closeModal}
                                >
                                    See how
                                </Link>
                            </div>
                        </div>
                    )}

                {selectedGame && gameIncludesCars(selectedGame.gameId) && (
                    <div className="w-full">
                        <p className="text-success font-bold text-2xl mb-2 text-center">You are ready to go</p>
                        <div className="w-full flex justify-between px-8 items-center">
                            <p className="text-xl py-2">We are waiting for your first lap!</p>

                            <Link
                                href="/dashboard"
                                className="bg-newOrange rounded p-2 font-bold text-lg w-40 text-center"
                                onClick={closeModal}
                            >
                                Go to Dashboard
                            </Link>
                        </div>
                    </div>
                )}

                {selectedGame === undefined && <p className="px-8 text-2xl">Choose a game to set up</p>}
            </div>
        </>
    );
};

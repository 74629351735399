import Image from 'next/image';

import SideNavGroup from 'components/SideNavGroup';
import SideNavItem from 'components/SideNavItem';

interface ITopLinks {
    handleClickAnalytics: (route: string) => () => void;
    isLinkActive: (route?: string) => boolean;
    isCollapsed?: boolean;
}

export const TopLinks = ({ handleClickAnalytics, isLinkActive, isCollapsed = false }: ITopLinks) => {
    return (
        <SideNavGroup name="">
            <SideNavItem link="/dashboard" onClick={handleClickAnalytics('dashboard')} isLinkActive={isLinkActive}>
                <Image
                    src="/dashboard-icon.svg"
                    alt="dashboard"
                    width="24"
                    height="24"
                    style={{
                        maxWidth: '100%',
                        height: 'auto'
                    }}
                />
                {!isCollapsed && 'Dashboard'}
            </SideNavItem>
        </SideNavGroup>
    );
};

import { sendAnalyticsEvent } from '../utils';

// Fired when the ghost is downloaded from the data logger modal

export const downloadGhostEvent = (email?: string, uuid?: string) =>
    sendAnalyticsEvent({
        uuid,
        email,
        eventName: 'download_ghost_click'
    });

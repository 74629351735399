import { useQuery } from 'react-query';
import { getSanity } from './getSanity';

export const useGetSanity = <T>({
    query,
    uuid,
    eventName,
    serverData
}: {
    query: string;
    uuid?: string;
    eventName?: string;
    serverData?: T;
}) => {
    const requestQuery = useQuery(['getSanity', { query, uuid, eventName }], () =>
        getSanity<T>(query, uuid, eventName)
    );

    if (!requestQuery.data && serverData) {
        return { ...requestQuery, data: serverData, isLoading: false, isError: false };
    }

    return requestQuery;
};

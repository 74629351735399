import { SanityNextImage } from 'components/SanityNextImage';
import { SupportedGame } from 'components/SupportedGames';
import Image from 'next/image';

interface GamesProps {
    supportedGames: SupportedGame[];
    onGameClick: (game: SupportedGame) => void;
}

export const Games = ({ supportedGames, onGameClick }: GamesProps) => {
    const supportedGamesList = supportedGames.map((game) => (
        <button
            key={game.name}
            className="w-full aspect-square p-1 hover:scale-105 active:scale-105  transition-all border-none outline-none"
            onClick={() => onGameClick(game)}
        >
            <SanityNextImage sanityImage={game.image} alt={game.name} className="rounded-lg " />
        </button>
    ));

    return (
        <div>
            <h2 className="text-2xl mb-4 text-center">{`Let's get set up...`}</h2>
            <div className="w-full flex justify-center my-4">
                <div className="w-48 h-48 overflow-hidden border-orange-600 border-4 rounded-full relative">
                    <Image src="/get-started.png" fill alt="Get Started" />
                </div>
            </div>
            <p className="text-lg my-2 text-center">Click on your game</p>
            <div className="grid grid-cols-3 grid-rows-2">{supportedGamesList}</div>{' '}
        </div>
    );
};

import { useMemo } from 'react';
import { UserType } from 'src/types';
import { LapUsageResponse } from 'src/queries/user/useGetLapUsage';
import Link from 'next/link';
import { sideCommunityBannerClickedEvent } from 'src/analytics/layout/sideCommunityBannerClicked';
import { VulcanResponse } from 'src/queries/types';

interface IHeaderUpgrade {
    user?: UserType;
    classNames?: string;
    lapUsageData?: VulcanResponse<LapUsageResponse>;
}

const HeaderUpgrade = ({ user, classNames, lapUsageData }: IHeaderUpgrade) => {
    const [lapsUsed, lapLimit] = useMemo(() => {
        return [lapUsageData?.data?.lapsRecorded ?? 0, lapUsageData?.data?.lapUsageLimit ?? 50];
    }, [lapUsageData]);

    return (
        <div className={`flex flex-col justify-center flex-shrink ml-0 lg:mr-8 ${classNames}`}>
            <div>
                {lapsUsed} of {lapLimit} Monthly Laps Used
            </div>
            <Link
                href="/pricing"
                className="bg-newOrange hover:bg-orange-700 text-white text-center rounded px-1 py-0.5"
                onClick={() => sideCommunityBannerClickedEvent(true, user?.email, user?.uuid)}
            >
                Get Unlimited Laps
            </Link>
        </div>
    );
};

export default HeaderUpgrade;

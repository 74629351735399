export const newShadow =
    '0px 14.3182px 14.3182px rgba(0, 0, 0, 0.14), 0px 28.6364px 14.3182px rgba(0, 0, 0, 0.12), 0px 14.3182px 42.9545px rgba(0, 0, 0, 0.2)';

export const profileShadow = '0px 5px 5px 2px rgba(0, 0, 0, 0.4)';

// TODO: move to tailwind
export const colours = {
    backgroundBlack: '#212121',
    backgroundGrey: '#424242',
    black: 'black',
    blue: '#2C6BB3',
    darkGrey: '#3D3D3D',
    darkOrange: '#FC591F',
    green: '#98DF33',
    lightGrey: 'rgba(255, 255, 255, 0.7)',
    orange: '#F1860D',
    pink: '#F534D7',
    purple: 'rgb(245, 52, 215)',
    red: '#D80021',
    white: 'white',
    'grey-00': '#131618',
    'grey-05': '#1F2224',
    'grey-08': '#26292A',
    'grey-11': '#2D3031',
    'grey-15': '#36393B',
    'grey-20': '#424546',
    'grey-25': '#5d6162'
};

// TODO: move all use cases to tailwind
export const mediaQuery =
    process.env.MOBILE_APP === 'true' || process.env.NEXT_PUBLIC_MOBILE_APP === 'true'
        ? {
              mobileMedium: '(min-width: 9999px)',
              mobileLarge: '(min-width: 9999px',
              tablet: '(min-width: 9999px)',
              laptop: '(min-width: 9999px)',
              midLaptop: '(min-width: 9999px)',
              largeLaptop: '(min-width: 9999px)'
          }
        : {
              mobileMedium: '(min-width: 375px)',
              mobileLarge: '(min-width: 400px)',
              tablet: '(min-width: 765px)',
              laptop: '(min-width: 1000px)',
              midLaptop: '(min-width: 1280px)',
              largeLaptop: '(min-width: 1440px)'
          };

export const rankBackgrounds = {
    Wood: '#82603C',
    Iron: 'linear-gradient(140.44deg, #67587D -2.37%, #48454D 14.4%, #BBB8BF 39.31%, rgba(138, 133, 147, 0.946772) 46.3%, rgba(77, 69, 91, 0.88) 59.79%, #E4E3E5 77.51%, #362B46 95.78%)',
    Bronze: 'linear-gradient(144.01deg, #37170C 17.78%, rgba(131, 87, 65, 0.880208) 29.38%, rgba(70, 40, 27, 0.755208) 41.86%, rgba(117, 89, 73, 0.609375) 49.79%, rgba(250, 226, 204, 0.473958) 66.74%, rgba(86, 70, 63, 0.437737) 76.28%, rgba(21, 8, 6, 0.415144) 80.76%, rgba(114, 79, 60, 0.385417) 87.37%, rgba(2, 1, 0, 0) 92.05%)',
    Silver: 'linear-gradient(321.99deg, #B1B1B1 7.11%, #8F8F8F 23.27%, #71706E 33.59%, #9D9C9B 42.58%, #CDCDCD 53.68%, #DCDCDC 65.75%, #848482 79.36%), linear-gradient(140.44deg, #67587D -2.37%, #48454D 14.4%, #BBB8BF 39.31%, rgba(138, 133, 147, 0.946772) 46.3%, rgba(77, 69, 91, 0.88) 59.79%, #E4E3E5 77.51%, #362B46 95.78%)',
    Gold: 'linear-gradient(320.87deg, #DEC489 23.48%, #F6E084 33.64%, #D1B372 48.83%, #E3CC97 65.9%, #BF9A53 74.36%)'
};

export type Rank = keyof typeof rankBackgrounds;

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

export const useResetBodyOverflow = () => {
    const pathname = usePathname();

    useEffect(() => {
        if (pathname !== '/sessions/[userId/[sessionId]/[lap]/[segment]' && document.body.style.overflow === 'hidden') {
            document.body.style.overflow = 'auto';
        }
    }, [pathname]);
};

import { useQuery } from 'react-query';
import { getLastSession } from './getLastSession';

const refetchInterval = 1000 * 60;

export const useGetLastSession = (userId?: string) => {
    const query = useQuery(['getLastSession', { userId }], () => getLastSession(userId), {
        refetchInterval
    });

    return query;
};
